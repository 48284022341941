.flyout-menu-item > * {
  @apply block w-full p-1 text-dark;
}

.flyout-menu-item > *:hover {
  @apply bg-greyBg;
}

.flyout-menu-item.flyout-menu-item-disabled > *,
.flyout-menu-item > *:disabled {
  @apply text-light cursor-default;
}

.flyout-menu-item.flyout-menu-item-disabled > *:hover {
  @apply bg-transparent;
}

@screen tablet {
  .flyout-menu-item > * {
    @apply py-1 text-sm;
  }
}
