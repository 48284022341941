.border-default {
  border-width: 1px;
  @apply border-solid border-border;
}

.border-l-default {
  border-width: 1px;
  @apply border-0 border-l border-solid border-border;
}

.border-r-default {
  border-width: 1px;
  @apply border-0 border-r border-solid border-border;
}

.border-t-default {
  border-width: 1px;
  @apply border-0 border-t border-solid border-border;
}

.border-b-default {
  border-width: 1px;
  @apply border-0 border-b border-solid border-border;
}
