/* custom autosize input with react-autosize-input  */

.auto-size > input {
  background: transparent;
}

.border-default {
  border-width: 1px;
  border-style: solid;
  --border-opacity: 1;
  border-color: #dfe2e6;
  border-color: rgba(223, 226, 230, var(--border-opacity));
}

.border-b-default {
  border-width: 1px;
  border-width: 0;
  border-bottom-width: 1px;
  border-style: solid;
  --border-opacity: 1;
  border-color: #dfe2e6;
  border-color: rgba(223, 226, 230, var(--border-opacity));
}

.btn {
  display: inline-block;
  border-radius: 0.25rem;
  text-align: center;
  padding: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  line-height: 20px;
  font-weight: 600;
  padding-top: 11px;
  padding-bottom: 13px;
}

.btn-sm {
  padding: 10px;
  font-size: 14px;
  line-height: 18px;
  padding-top: 7px;
  padding-bottom: 9px;
}

.btn:disabled {
  opacity: 0.75;
  cursor: not-allowed;
}

.btn:hover {
  text-decoration: none;
}

.btn-primary {
  --bg-opacity: 1;
  background-color: #FC2361;
  background-color: rgba(252, 35, 97, var(--bg-opacity));
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity));
  font-weight: 600;
}

.btn-primary:hover {
  --bg-opacity: 1;
  background-color: #FF3D71;
  background-color: rgba(255, 61, 113, var(--bg-opacity));
}

.btn-secondary {
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  --text-opacity: 1;
  color: #FC2361;
  color: rgba(252, 35, 97, var(--text-opacity));
  border-width: 1px;
  border-color: currentColor;
}

.btn-secondary:hover {
  --text-opacity: 1;
  color: #FF3D71;
  color: rgba(255, 61, 113, var(--text-opacity));
}

.btn-error {
  --bg-opacity: 1;
  background-color: #ffeded;
  background-color: rgba(255, 237, 237, var(--bg-opacity));
  --text-opacity: 1;
  color: #FF0000;
  color: rgba(255, 0, 0, var(--text-opacity));
}

.btn-default {
  --bg-opacity: 1;
  background-color: #dfe2e6;
  background-color: rgba(223, 226, 230, var(--bg-opacity));
  --text-opacity: 1;
  color: #101820;
  color: rgba(16, 24, 32, var(--text-opacity));
}

.btn-default:hover {
  --bg-opacity: 1;
  background-color: #c9ced4;
  background-color: rgba(201, 206, 212, var(--bg-opacity));
}

.bump-down-1 {
  position: relative;
  top: 1px;
}

.bump-up-0\\.5 {
  position: relative;
  top: -0.5px;
}

.bump-up-1 {
  position: relative;
  top: -1px;
}

.card {
  border-radius: 0.25rem;
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  padding: 16px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  margin-bottom: 16px;
}

.flyout-menu-item > * {
  display: block;
  width: 100%;
  padding: 8px;
  --text-opacity: 1;
  color: #101820;
  color: rgba(16, 24, 32, var(--text-opacity));
}

.flyout-menu-item > *:hover {
  --bg-opacity: 1;
  background-color: #f0f2f5;
  background-color: rgba(240, 242, 245, var(--bg-opacity));
}

.flyout-menu-item.flyout-menu-item-disabled > *,
.flyout-menu-item > *:disabled {
  --text-opacity: 1;
  color: #9198a1;
  color: rgba(145, 152, 161, var(--text-opacity));
  cursor: default;
}

.flyout-menu-item.flyout-menu-item-disabled > *:hover {
  background-color: transparent;
}

@media (min-width: 768px) {
  .flyout-menu-item > * {
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 14px;
  }
}

.input {
  width: 100%;
  height: 36px;
  padding: 8px;
  display: block;
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  border-width: 1px;
  border-style: solid;
  --border-opacity: 1;
  border-color: #dfe2e6;
  border-color: rgba(223, 226, 230, var(--border-opacity));
  border-radius: 0.25rem;
  font-family: sofia-pro, sans-serif;
}

.input::-webkit-input-placeholder {
  --text-opacity: 1;
  color: #9198a1;
  color: rgba(145, 152, 161, var(--text-opacity));
}

.input::-ms-input-placeholder {
  --text-opacity: 1;
  color: #9198a1;
  color: rgba(145, 152, 161, var(--text-opacity));
}

.input::placeholder {
  --text-opacity: 1;
  color: #9198a1;
  color: rgba(145, 152, 161, var(--text-opacity));
}

input:focus {
  outline: 0;
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}

.tippy-tooltip.socialie-theme {
  border-radius: 0.125rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 8px;
  padding-right: 8px;
  --text-opacity: 1;
  color: #5a636e;
  color: rgba(90, 99, 110, var(--text-opacity));
}

.tippy-tooltip.FlyoutMenu-theme {
  border-radius: 0.125rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 8px;
  padding-right: 8px;
  --text-opacity: 1;
  color: #5a636e;
  color: rgba(90, 99, 110, var(--text-opacity));
  overflow-y: auto;
  text-align: left;
  min-width: 12rem;
  max-height: 16rem;
}

*,
:after,
:before {
  box-sizing: border-box;
  margin: 0;
  --border-opacity: 1;
  border-color: #dfe2e6;
  border-color: rgba(223, 226, 230, var(--border-opacity));
  border-top-style: solid;
  border-top-width: 0px;
  border-right-style: solid;
  border-right-width: 0px;
  border-bottom-style: solid;
  border-bottom-width: 0px;
  border-left-style: solid;
  border-left-width: 0px;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
}

html {
  height: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  -webkit-font-smoothing: antialiased;
  font-family: sofia-pro, sans-serif;
}

a {
  text-decoration: none;
}

button {
  outline: none;
  border: 0;
  background: none;
  cursor: pointer;
  padding: 0;
  text-align: inherit;
  text-transform: inherit;
  letter-spacing: inherit;
  color: inherit;
  font: inherit;
}

button:focus {
  outline: none;
}

.container {
  width: 100%;
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1170px) {
  .container {
    max-width: 1170px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

.space-x-1 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(8px * var(--space-x-reverse));
  margin-left: calc(8px * calc(1 - var(--space-x-reverse)));
}

.space-x-2 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(16px * var(--space-x-reverse));
  margin-left: calc(16px * calc(1 - var(--space-x-reverse)));
}

.space-x-3 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(24px * var(--space-x-reverse));
  margin-left: calc(24px * calc(1 - var(--space-x-reverse)));
}

.space-y-0\.5 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(4px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(4px * var(--space-y-reverse));
}

.space-x-1\.5 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(12px * var(--space-x-reverse));
  margin-left: calc(12px * calc(1 - var(--space-x-reverse)));
}

.appearance-none {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

.bg-fixed {
  background-attachment: fixed;
}

.bg-local {
  background-attachment: local;
}

.bg-scroll {
  background-attachment: scroll;
}

.bg-transparent {
  background-color: transparent;
}

.bg-grey1 {
  --bg-opacity: 1;
  background-color: #101820;
  background-color: rgba(16, 24, 32, var(--bg-opacity));
}

.bg-grey2 {
  --bg-opacity: 1;
  background-color: #1c252f;
  background-color: rgba(28, 37, 47, var(--bg-opacity));
}

.bg-grey3 {
  --bg-opacity: 1;
  background-color: #343d46;
  background-color: rgba(52, 61, 70, var(--bg-opacity));
}

.bg-grey4 {
  --bg-opacity: 1;
  background-color: #5a636e;
  background-color: rgba(90, 99, 110, var(--bg-opacity));
}

.bg-grey5 {
  --bg-opacity: 1;
  background-color: #9198a1;
  background-color: rgba(145, 152, 161, var(--bg-opacity));
}

.bg-grey6 {
  --bg-opacity: 1;
  background-color: #c9ced4;
  background-color: rgba(201, 206, 212, var(--bg-opacity));
}

.bg-grey7 {
  --bg-opacity: 1;
  background-color: #dfe2e6;
  background-color: rgba(223, 226, 230, var(--bg-opacity));
}

.bg-grey8 {
  --bg-opacity: 1;
  background-color: #f0f2f5;
  background-color: rgba(240, 242, 245, var(--bg-opacity));
}

.bg-grey9 {
  --bg-opacity: 1;
  background-color: #f5f7fa;
  background-color: rgba(245, 247, 250, var(--bg-opacity));
}

.bg-white {
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
}

.bg-black {
  --bg-opacity: 1;
  background-color: #000;
  background-color: rgba(0, 0, 0, var(--bg-opacity));
}

.bg-lightTeal {
  --bg-opacity: 1;
  background-color: #edf8f8;
  background-color: rgba(237, 248, 248, var(--bg-opacity));
}

.bg-teal {
  --bg-opacity: 1;
  background-color: #46b4b4;
  background-color: rgba(70, 180, 180, var(--bg-opacity));
}

.bg-darkTeal {
  --bg-opacity: 1;
  background-color: #46b4b4;
  background-color: rgba(70, 180, 180, var(--bg-opacity));
}

.bg-tealBg {
  --bg-opacity: 1;
  background-color: #eff8f8;
  background-color: rgba(239, 248, 248, var(--bg-opacity));
}

.bg-yellow {
  --bg-opacity: 1;
  background-color: #ff9f40;
  background-color: rgba(255, 159, 64, var(--bg-opacity));
}

.bg-yellowBg {
  --bg-opacity: 1;
  background-color: #fff5eb;
  background-color: rgba(255, 245, 235, var(--bg-opacity));
}

.bg-blue {
  --bg-opacity: 1;
  background-color: #569fe5;
  background-color: rgba(86, 159, 229, var(--bg-opacity));
}

.bg-blueBg {
  --bg-opacity: 1;
  background-color: #ecf6fd;
  background-color: rgba(236, 246, 253, var(--bg-opacity));
}

.bg-red {
  --bg-opacity: 1;
  background-color: #ed6c85;
  background-color: rgba(237, 108, 133, var(--bg-opacity));
}

.bg-redBg {
  --bg-opacity: 1;
  background-color: #fdeff2;
  background-color: rgba(253, 239, 242, var(--bg-opacity));
}

.bg-green {
  --bg-opacity: 1;
  background-color: #4bc0c0;
  background-color: rgba(75, 192, 192, var(--bg-opacity));
}

.bg-error {
  --bg-opacity: 1;
  background-color: #FF0000;
  background-color: rgba(255, 0, 0, var(--bg-opacity));
}

.bg-errorBg {
  --bg-opacity: 1;
  background-color: #ffeded;
  background-color: rgba(255, 237, 237, var(--bg-opacity));
}

.bg-facebook {
  --bg-opacity: 1;
  background-color: #1877f2;
  background-color: rgba(24, 119, 242, var(--bg-opacity));
}

.bg-instagram {
  --bg-opacity: 1;
  background-color: #e73558;
  background-color: rgba(231, 53, 88, var(--bg-opacity));
}

.bg-instagram_basic {
  --bg-opacity: 1;
  background-color: #e73558;
  background-color: rgba(231, 53, 88, var(--bg-opacity));
}

.bg-instagram_graph {
  --bg-opacity: 1;
  background-color: #e73558;
  background-color: rgba(231, 53, 88, var(--bg-opacity));
}

.bg-linkedin {
  --bg-opacity: 1;
  background-color: #1b73b7;
  background-color: rgba(27, 115, 183, var(--bg-opacity));
}

.bg-twitter {
  --bg-opacity: 1;
  background-color: #52a8f0;
  background-color: rgba(82, 168, 240, var(--bg-opacity));
}

.bg-whatsAppGreen {
  --bg-opacity: 1;
  background-color: #25D366;
  background-color: rgba(37, 211, 102, var(--bg-opacity));
}

.bg-socialiePink {
  --bg-opacity: 1;
  background-color: #FC2361;
  background-color: rgba(252, 35, 97, var(--bg-opacity));
}

.bg-socialiePinkHover {
  --bg-opacity: 1;
  background-color: #FF3D71;
  background-color: rgba(255, 61, 113, var(--bg-opacity));
}

.bg-socialieBlue {
  --bg-opacity: 1;
  background-color: #5CC7EA;
  background-color: rgba(92, 199, 234, var(--bg-opacity));
}

.bg-badgeBlue {
  --bg-opacity: 1;
  background-color: #1c90ff;
  background-color: rgba(28, 144, 255, var(--bg-opacity));
}

.bg-success {
  --bg-opacity: 1;
  background-color: #97cc04;
  background-color: rgba(151, 204, 4, var(--bg-opacity));
}

.bg-bodyText {
  --bg-opacity: 1;
  background-color: #5a636e;
  background-color: rgba(90, 99, 110, var(--bg-opacity));
}

.bg-bodyBg {
  --bg-opacity: 1;
  background-color: #f5f7fa;
  background-color: rgba(245, 247, 250, var(--bg-opacity));
}

.bg-border {
  --bg-opacity: 1;
  background-color: #dfe2e6;
  background-color: rgba(223, 226, 230, var(--bg-opacity));
}

.bg-boxShadow {
  background-color: rgba(0, 0, 0, 0.12);
}

.bg-darkBorder {
  --bg-opacity: 1;
  background-color: #c9ced4;
  background-color: rgba(201, 206, 212, var(--bg-opacity));
}

.bg-darkerBorder {
  --bg-opacity: 1;
  background-color: #5a636e;
  background-color: rgba(90, 99, 110, var(--bg-opacity));
}

.bg-darkestGreyBg {
  --bg-opacity: 1;
  background-color: #5a636e;
  background-color: rgba(90, 99, 110, var(--bg-opacity));
}

.bg-darkGreyBg {
  --bg-opacity: 1;
  background-color: #c9ced4;
  background-color: rgba(201, 206, 212, var(--bg-opacity));
}

.bg-darkText {
  --bg-opacity: 1;
  background-color: #101820;
  background-color: rgba(16, 24, 32, var(--bg-opacity));
}

.bg-draftBuilderBg {
  --bg-opacity: 1;
  background-color: #1c252f;
  background-color: rgba(28, 37, 47, var(--bg-opacity));
}

.bg-semiBlack {
  background-color: rgba(0, 0, 0, 0.8);
}

.bg-semiWhite {
  background-color: rgba(255, 255, 255, 0.8);
}

.bg-focus {
  --bg-opacity: 1;
  background-color: #c9ced4;
  background-color: rgba(201, 206, 212, var(--bg-opacity));
}

.bg-grey {
  --bg-opacity: 1;
  background-color: #5a636e;
  background-color: rgba(90, 99, 110, var(--bg-opacity));
}

.bg-greyBg {
  --bg-opacity: 1;
  background-color: #f0f2f5;
  background-color: rgba(240, 242, 245, var(--bg-opacity));
}

.bg-hoverBg {
  background-color: rgba(240,242,245,0.25);
}

.bg-inverseBorder {
  --bg-opacity: 1;
  background-color: #343d46;
  background-color: rgba(52, 61, 70, var(--bg-opacity));
}

.bg-messageBg {
  --bg-opacity: 1;
  background-color: #36a2eb;
  background-color: rgba(54, 162, 235, var(--bg-opacity));
}

.bg-lightBorder {
  --bg-opacity: 1;
  background-color: #f0f2f5;
  background-color: rgba(240, 242, 245, var(--bg-opacity));
}

.bg-lighterText {
  --bg-opacity: 1;
  background-color: #c9ced4;
  background-color: rgba(201, 206, 212, var(--bg-opacity));
}

.bg-lightIcon {
  --bg-opacity: 1;
  background-color: #c9ced4;
  background-color: rgba(201, 206, 212, var(--bg-opacity));
}

.bg-lightText {
  --bg-opacity: 1;
  background-color: #9198a1;
  background-color: rgba(145, 152, 161, var(--bg-opacity));
}

.bg-link {
  --bg-opacity: 1;
  background-color: #569fe5;
  background-color: rgba(86, 159, 229, var(--bg-opacity));
}

.bg-default {
  --bg-opacity: 1;
  background-color: #5a636e;
  background-color: rgba(90, 99, 110, var(--bg-opacity));
}

.hover\:bg-transparent:hover {
  background-color: transparent;
}

.hover\:bg-grey1:hover {
  --bg-opacity: 1;
  background-color: #101820;
  background-color: rgba(16, 24, 32, var(--bg-opacity));
}

.hover\:bg-grey2:hover {
  --bg-opacity: 1;
  background-color: #1c252f;
  background-color: rgba(28, 37, 47, var(--bg-opacity));
}

.hover\:bg-grey3:hover {
  --bg-opacity: 1;
  background-color: #343d46;
  background-color: rgba(52, 61, 70, var(--bg-opacity));
}

.hover\:bg-grey4:hover {
  --bg-opacity: 1;
  background-color: #5a636e;
  background-color: rgba(90, 99, 110, var(--bg-opacity));
}

.hover\:bg-grey5:hover {
  --bg-opacity: 1;
  background-color: #9198a1;
  background-color: rgba(145, 152, 161, var(--bg-opacity));
}

.hover\:bg-grey6:hover {
  --bg-opacity: 1;
  background-color: #c9ced4;
  background-color: rgba(201, 206, 212, var(--bg-opacity));
}

.hover\:bg-grey7:hover {
  --bg-opacity: 1;
  background-color: #dfe2e6;
  background-color: rgba(223, 226, 230, var(--bg-opacity));
}

.hover\:bg-grey8:hover {
  --bg-opacity: 1;
  background-color: #f0f2f5;
  background-color: rgba(240, 242, 245, var(--bg-opacity));
}

.hover\:bg-grey9:hover {
  --bg-opacity: 1;
  background-color: #f5f7fa;
  background-color: rgba(245, 247, 250, var(--bg-opacity));
}

.hover\:bg-white:hover {
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
}

.hover\:bg-black:hover {
  --bg-opacity: 1;
  background-color: #000;
  background-color: rgba(0, 0, 0, var(--bg-opacity));
}

.hover\:bg-lightTeal:hover {
  --bg-opacity: 1;
  background-color: #edf8f8;
  background-color: rgba(237, 248, 248, var(--bg-opacity));
}

.hover\:bg-teal:hover {
  --bg-opacity: 1;
  background-color: #46b4b4;
  background-color: rgba(70, 180, 180, var(--bg-opacity));
}

.hover\:bg-darkTeal:hover {
  --bg-opacity: 1;
  background-color: #46b4b4;
  background-color: rgba(70, 180, 180, var(--bg-opacity));
}

.hover\:bg-tealBg:hover {
  --bg-opacity: 1;
  background-color: #eff8f8;
  background-color: rgba(239, 248, 248, var(--bg-opacity));
}

.hover\:bg-yellow:hover {
  --bg-opacity: 1;
  background-color: #ff9f40;
  background-color: rgba(255, 159, 64, var(--bg-opacity));
}

.hover\:bg-yellowBg:hover {
  --bg-opacity: 1;
  background-color: #fff5eb;
  background-color: rgba(255, 245, 235, var(--bg-opacity));
}

.hover\:bg-blue:hover {
  --bg-opacity: 1;
  background-color: #569fe5;
  background-color: rgba(86, 159, 229, var(--bg-opacity));
}

.hover\:bg-blueBg:hover {
  --bg-opacity: 1;
  background-color: #ecf6fd;
  background-color: rgba(236, 246, 253, var(--bg-opacity));
}

.hover\:bg-red:hover {
  --bg-opacity: 1;
  background-color: #ed6c85;
  background-color: rgba(237, 108, 133, var(--bg-opacity));
}

.hover\:bg-redBg:hover {
  --bg-opacity: 1;
  background-color: #fdeff2;
  background-color: rgba(253, 239, 242, var(--bg-opacity));
}

.hover\:bg-green:hover {
  --bg-opacity: 1;
  background-color: #4bc0c0;
  background-color: rgba(75, 192, 192, var(--bg-opacity));
}

.hover\:bg-error:hover {
  --bg-opacity: 1;
  background-color: #FF0000;
  background-color: rgba(255, 0, 0, var(--bg-opacity));
}

.hover\:bg-errorBg:hover {
  --bg-opacity: 1;
  background-color: #ffeded;
  background-color: rgba(255, 237, 237, var(--bg-opacity));
}

.hover\:bg-facebook:hover {
  --bg-opacity: 1;
  background-color: #1877f2;
  background-color: rgba(24, 119, 242, var(--bg-opacity));
}

.hover\:bg-instagram:hover {
  --bg-opacity: 1;
  background-color: #e73558;
  background-color: rgba(231, 53, 88, var(--bg-opacity));
}

.hover\:bg-instagram_basic:hover {
  --bg-opacity: 1;
  background-color: #e73558;
  background-color: rgba(231, 53, 88, var(--bg-opacity));
}

.hover\:bg-instagram_graph:hover {
  --bg-opacity: 1;
  background-color: #e73558;
  background-color: rgba(231, 53, 88, var(--bg-opacity));
}

.hover\:bg-linkedin:hover {
  --bg-opacity: 1;
  background-color: #1b73b7;
  background-color: rgba(27, 115, 183, var(--bg-opacity));
}

.hover\:bg-twitter:hover {
  --bg-opacity: 1;
  background-color: #52a8f0;
  background-color: rgba(82, 168, 240, var(--bg-opacity));
}

.hover\:bg-whatsAppGreen:hover {
  --bg-opacity: 1;
  background-color: #25D366;
  background-color: rgba(37, 211, 102, var(--bg-opacity));
}

.hover\:bg-socialiePink:hover {
  --bg-opacity: 1;
  background-color: #FC2361;
  background-color: rgba(252, 35, 97, var(--bg-opacity));
}

.hover\:bg-socialiePinkHover:hover {
  --bg-opacity: 1;
  background-color: #FF3D71;
  background-color: rgba(255, 61, 113, var(--bg-opacity));
}

.hover\:bg-socialieBlue:hover {
  --bg-opacity: 1;
  background-color: #5CC7EA;
  background-color: rgba(92, 199, 234, var(--bg-opacity));
}

.hover\:bg-badgeBlue:hover {
  --bg-opacity: 1;
  background-color: #1c90ff;
  background-color: rgba(28, 144, 255, var(--bg-opacity));
}

.hover\:bg-success:hover {
  --bg-opacity: 1;
  background-color: #97cc04;
  background-color: rgba(151, 204, 4, var(--bg-opacity));
}

.hover\:bg-bodyText:hover {
  --bg-opacity: 1;
  background-color: #5a636e;
  background-color: rgba(90, 99, 110, var(--bg-opacity));
}

.hover\:bg-bodyBg:hover {
  --bg-opacity: 1;
  background-color: #f5f7fa;
  background-color: rgba(245, 247, 250, var(--bg-opacity));
}

.hover\:bg-border:hover {
  --bg-opacity: 1;
  background-color: #dfe2e6;
  background-color: rgba(223, 226, 230, var(--bg-opacity));
}

.hover\:bg-boxShadow:hover {
  background-color: rgba(0, 0, 0, 0.12);
}

.hover\:bg-darkBorder:hover {
  --bg-opacity: 1;
  background-color: #c9ced4;
  background-color: rgba(201, 206, 212, var(--bg-opacity));
}

.hover\:bg-darkerBorder:hover {
  --bg-opacity: 1;
  background-color: #5a636e;
  background-color: rgba(90, 99, 110, var(--bg-opacity));
}

.hover\:bg-darkestGreyBg:hover {
  --bg-opacity: 1;
  background-color: #5a636e;
  background-color: rgba(90, 99, 110, var(--bg-opacity));
}

.hover\:bg-darkGreyBg:hover {
  --bg-opacity: 1;
  background-color: #c9ced4;
  background-color: rgba(201, 206, 212, var(--bg-opacity));
}

.hover\:bg-darkText:hover {
  --bg-opacity: 1;
  background-color: #101820;
  background-color: rgba(16, 24, 32, var(--bg-opacity));
}

.hover\:bg-draftBuilderBg:hover {
  --bg-opacity: 1;
  background-color: #1c252f;
  background-color: rgba(28, 37, 47, var(--bg-opacity));
}

.hover\:bg-semiBlack:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.hover\:bg-semiWhite:hover {
  background-color: rgba(255, 255, 255, 0.8);
}

.hover\:bg-focus:hover {
  --bg-opacity: 1;
  background-color: #c9ced4;
  background-color: rgba(201, 206, 212, var(--bg-opacity));
}

.hover\:bg-grey:hover {
  --bg-opacity: 1;
  background-color: #5a636e;
  background-color: rgba(90, 99, 110, var(--bg-opacity));
}

.hover\:bg-greyBg:hover {
  --bg-opacity: 1;
  background-color: #f0f2f5;
  background-color: rgba(240, 242, 245, var(--bg-opacity));
}

.hover\:bg-hoverBg:hover {
  background-color: rgba(240,242,245,0.25);
}

.hover\:bg-inverseBorder:hover {
  --bg-opacity: 1;
  background-color: #343d46;
  background-color: rgba(52, 61, 70, var(--bg-opacity));
}

.hover\:bg-messageBg:hover {
  --bg-opacity: 1;
  background-color: #36a2eb;
  background-color: rgba(54, 162, 235, var(--bg-opacity));
}

.hover\:bg-lightBorder:hover {
  --bg-opacity: 1;
  background-color: #f0f2f5;
  background-color: rgba(240, 242, 245, var(--bg-opacity));
}

.hover\:bg-lighterText:hover {
  --bg-opacity: 1;
  background-color: #c9ced4;
  background-color: rgba(201, 206, 212, var(--bg-opacity));
}

.hover\:bg-lightIcon:hover {
  --bg-opacity: 1;
  background-color: #c9ced4;
  background-color: rgba(201, 206, 212, var(--bg-opacity));
}

.hover\:bg-lightText:hover {
  --bg-opacity: 1;
  background-color: #9198a1;
  background-color: rgba(145, 152, 161, var(--bg-opacity));
}

.hover\:bg-link:hover {
  --bg-opacity: 1;
  background-color: #569fe5;
  background-color: rgba(86, 159, 229, var(--bg-opacity));
}

.hover\:bg-default:hover {
  --bg-opacity: 1;
  background-color: #5a636e;
  background-color: rgba(90, 99, 110, var(--bg-opacity));
}

.focus\:bg-transparent:focus {
  background-color: transparent;
}

.focus\:bg-grey1:focus {
  --bg-opacity: 1;
  background-color: #101820;
  background-color: rgba(16, 24, 32, var(--bg-opacity));
}

.focus\:bg-grey2:focus {
  --bg-opacity: 1;
  background-color: #1c252f;
  background-color: rgba(28, 37, 47, var(--bg-opacity));
}

.focus\:bg-grey3:focus {
  --bg-opacity: 1;
  background-color: #343d46;
  background-color: rgba(52, 61, 70, var(--bg-opacity));
}

.focus\:bg-grey4:focus {
  --bg-opacity: 1;
  background-color: #5a636e;
  background-color: rgba(90, 99, 110, var(--bg-opacity));
}

.focus\:bg-grey5:focus {
  --bg-opacity: 1;
  background-color: #9198a1;
  background-color: rgba(145, 152, 161, var(--bg-opacity));
}

.focus\:bg-grey6:focus {
  --bg-opacity: 1;
  background-color: #c9ced4;
  background-color: rgba(201, 206, 212, var(--bg-opacity));
}

.focus\:bg-grey7:focus {
  --bg-opacity: 1;
  background-color: #dfe2e6;
  background-color: rgba(223, 226, 230, var(--bg-opacity));
}

.focus\:bg-grey8:focus {
  --bg-opacity: 1;
  background-color: #f0f2f5;
  background-color: rgba(240, 242, 245, var(--bg-opacity));
}

.focus\:bg-grey9:focus {
  --bg-opacity: 1;
  background-color: #f5f7fa;
  background-color: rgba(245, 247, 250, var(--bg-opacity));
}

.focus\:bg-white:focus {
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
}

.focus\:bg-black:focus {
  --bg-opacity: 1;
  background-color: #000;
  background-color: rgba(0, 0, 0, var(--bg-opacity));
}

.focus\:bg-lightTeal:focus {
  --bg-opacity: 1;
  background-color: #edf8f8;
  background-color: rgba(237, 248, 248, var(--bg-opacity));
}

.focus\:bg-teal:focus {
  --bg-opacity: 1;
  background-color: #46b4b4;
  background-color: rgba(70, 180, 180, var(--bg-opacity));
}

.focus\:bg-darkTeal:focus {
  --bg-opacity: 1;
  background-color: #46b4b4;
  background-color: rgba(70, 180, 180, var(--bg-opacity));
}

.focus\:bg-tealBg:focus {
  --bg-opacity: 1;
  background-color: #eff8f8;
  background-color: rgba(239, 248, 248, var(--bg-opacity));
}

.focus\:bg-yellow:focus {
  --bg-opacity: 1;
  background-color: #ff9f40;
  background-color: rgba(255, 159, 64, var(--bg-opacity));
}

.focus\:bg-yellowBg:focus {
  --bg-opacity: 1;
  background-color: #fff5eb;
  background-color: rgba(255, 245, 235, var(--bg-opacity));
}

.focus\:bg-blue:focus {
  --bg-opacity: 1;
  background-color: #569fe5;
  background-color: rgba(86, 159, 229, var(--bg-opacity));
}

.focus\:bg-blueBg:focus {
  --bg-opacity: 1;
  background-color: #ecf6fd;
  background-color: rgba(236, 246, 253, var(--bg-opacity));
}

.focus\:bg-red:focus {
  --bg-opacity: 1;
  background-color: #ed6c85;
  background-color: rgba(237, 108, 133, var(--bg-opacity));
}

.focus\:bg-redBg:focus {
  --bg-opacity: 1;
  background-color: #fdeff2;
  background-color: rgba(253, 239, 242, var(--bg-opacity));
}

.focus\:bg-green:focus {
  --bg-opacity: 1;
  background-color: #4bc0c0;
  background-color: rgba(75, 192, 192, var(--bg-opacity));
}

.focus\:bg-error:focus {
  --bg-opacity: 1;
  background-color: #FF0000;
  background-color: rgba(255, 0, 0, var(--bg-opacity));
}

.focus\:bg-errorBg:focus {
  --bg-opacity: 1;
  background-color: #ffeded;
  background-color: rgba(255, 237, 237, var(--bg-opacity));
}

.focus\:bg-facebook:focus {
  --bg-opacity: 1;
  background-color: #1877f2;
  background-color: rgba(24, 119, 242, var(--bg-opacity));
}

.focus\:bg-instagram:focus {
  --bg-opacity: 1;
  background-color: #e73558;
  background-color: rgba(231, 53, 88, var(--bg-opacity));
}

.focus\:bg-instagram_basic:focus {
  --bg-opacity: 1;
  background-color: #e73558;
  background-color: rgba(231, 53, 88, var(--bg-opacity));
}

.focus\:bg-instagram_graph:focus {
  --bg-opacity: 1;
  background-color: #e73558;
  background-color: rgba(231, 53, 88, var(--bg-opacity));
}

.focus\:bg-linkedin:focus {
  --bg-opacity: 1;
  background-color: #1b73b7;
  background-color: rgba(27, 115, 183, var(--bg-opacity));
}

.focus\:bg-twitter:focus {
  --bg-opacity: 1;
  background-color: #52a8f0;
  background-color: rgba(82, 168, 240, var(--bg-opacity));
}

.focus\:bg-whatsAppGreen:focus {
  --bg-opacity: 1;
  background-color: #25D366;
  background-color: rgba(37, 211, 102, var(--bg-opacity));
}

.focus\:bg-socialiePink:focus {
  --bg-opacity: 1;
  background-color: #FC2361;
  background-color: rgba(252, 35, 97, var(--bg-opacity));
}

.focus\:bg-socialiePinkHover:focus {
  --bg-opacity: 1;
  background-color: #FF3D71;
  background-color: rgba(255, 61, 113, var(--bg-opacity));
}

.focus\:bg-socialieBlue:focus {
  --bg-opacity: 1;
  background-color: #5CC7EA;
  background-color: rgba(92, 199, 234, var(--bg-opacity));
}

.focus\:bg-badgeBlue:focus {
  --bg-opacity: 1;
  background-color: #1c90ff;
  background-color: rgba(28, 144, 255, var(--bg-opacity));
}

.focus\:bg-success:focus {
  --bg-opacity: 1;
  background-color: #97cc04;
  background-color: rgba(151, 204, 4, var(--bg-opacity));
}

.focus\:bg-bodyText:focus {
  --bg-opacity: 1;
  background-color: #5a636e;
  background-color: rgba(90, 99, 110, var(--bg-opacity));
}

.focus\:bg-bodyBg:focus {
  --bg-opacity: 1;
  background-color: #f5f7fa;
  background-color: rgba(245, 247, 250, var(--bg-opacity));
}

.focus\:bg-border:focus {
  --bg-opacity: 1;
  background-color: #dfe2e6;
  background-color: rgba(223, 226, 230, var(--bg-opacity));
}

.focus\:bg-boxShadow:focus {
  background-color: rgba(0, 0, 0, 0.12);
}

.focus\:bg-darkBorder:focus {
  --bg-opacity: 1;
  background-color: #c9ced4;
  background-color: rgba(201, 206, 212, var(--bg-opacity));
}

.focus\:bg-darkerBorder:focus {
  --bg-opacity: 1;
  background-color: #5a636e;
  background-color: rgba(90, 99, 110, var(--bg-opacity));
}

.focus\:bg-darkestGreyBg:focus {
  --bg-opacity: 1;
  background-color: #5a636e;
  background-color: rgba(90, 99, 110, var(--bg-opacity));
}

.focus\:bg-darkGreyBg:focus {
  --bg-opacity: 1;
  background-color: #c9ced4;
  background-color: rgba(201, 206, 212, var(--bg-opacity));
}

.focus\:bg-darkText:focus {
  --bg-opacity: 1;
  background-color: #101820;
  background-color: rgba(16, 24, 32, var(--bg-opacity));
}

.focus\:bg-draftBuilderBg:focus {
  --bg-opacity: 1;
  background-color: #1c252f;
  background-color: rgba(28, 37, 47, var(--bg-opacity));
}

.focus\:bg-semiBlack:focus {
  background-color: rgba(0, 0, 0, 0.8);
}

.focus\:bg-semiWhite:focus {
  background-color: rgba(255, 255, 255, 0.8);
}

.focus\:bg-focus:focus {
  --bg-opacity: 1;
  background-color: #c9ced4;
  background-color: rgba(201, 206, 212, var(--bg-opacity));
}

.focus\:bg-grey:focus {
  --bg-opacity: 1;
  background-color: #5a636e;
  background-color: rgba(90, 99, 110, var(--bg-opacity));
}

.focus\:bg-greyBg:focus {
  --bg-opacity: 1;
  background-color: #f0f2f5;
  background-color: rgba(240, 242, 245, var(--bg-opacity));
}

.focus\:bg-hoverBg:focus {
  background-color: rgba(240,242,245,0.25);
}

.focus\:bg-inverseBorder:focus {
  --bg-opacity: 1;
  background-color: #343d46;
  background-color: rgba(52, 61, 70, var(--bg-opacity));
}

.focus\:bg-messageBg:focus {
  --bg-opacity: 1;
  background-color: #36a2eb;
  background-color: rgba(54, 162, 235, var(--bg-opacity));
}

.focus\:bg-lightBorder:focus {
  --bg-opacity: 1;
  background-color: #f0f2f5;
  background-color: rgba(240, 242, 245, var(--bg-opacity));
}

.focus\:bg-lighterText:focus {
  --bg-opacity: 1;
  background-color: #c9ced4;
  background-color: rgba(201, 206, 212, var(--bg-opacity));
}

.focus\:bg-lightIcon:focus {
  --bg-opacity: 1;
  background-color: #c9ced4;
  background-color: rgba(201, 206, 212, var(--bg-opacity));
}

.focus\:bg-lightText:focus {
  --bg-opacity: 1;
  background-color: #9198a1;
  background-color: rgba(145, 152, 161, var(--bg-opacity));
}

.focus\:bg-link:focus {
  --bg-opacity: 1;
  background-color: #569fe5;
  background-color: rgba(86, 159, 229, var(--bg-opacity));
}

.focus\:bg-default:focus {
  --bg-opacity: 1;
  background-color: #5a636e;
  background-color: rgba(90, 99, 110, var(--bg-opacity));
}

.bg-opacity-0 {
  --bg-opacity: 0;
}

.bg-opacity-10 {
  --bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bg-opacity: 0.75;
}

.bg-opacity-92 {
  --bg-opacity: 0.92px;
}

.bg-opacity-100 {
  --bg-opacity: 1;
}

.hover\:bg-opacity-0:hover {
  --bg-opacity: 0;
}

.hover\:bg-opacity-10:hover {
  --bg-opacity: 0.1;
}

.hover\:bg-opacity-25:hover {
  --bg-opacity: 0.25;
}

.hover\:bg-opacity-50:hover {
  --bg-opacity: 0.5;
}

.hover\:bg-opacity-75:hover {
  --bg-opacity: 0.75;
}

.hover\:bg-opacity-92:hover {
  --bg-opacity: 0.92px;
}

.hover\:bg-opacity-100:hover {
  --bg-opacity: 1;
}

.focus\:bg-opacity-0:focus {
  --bg-opacity: 0;
}

.focus\:bg-opacity-10:focus {
  --bg-opacity: 0.1;
}

.focus\:bg-opacity-25:focus {
  --bg-opacity: 0.25;
}

.focus\:bg-opacity-50:focus {
  --bg-opacity: 0.5;
}

.focus\:bg-opacity-75:focus {
  --bg-opacity: 0.75;
}

.focus\:bg-opacity-92:focus {
  --bg-opacity: 0.92px;
}

.focus\:bg-opacity-100:focus {
  --bg-opacity: 1;
}

.bg-bottom {
  background-position: bottom;
}

.bg-center {
  background-position: center;
}

.bg-left {
  background-position: left;
}

.bg-left-bottom {
  background-position: left bottom;
}

.bg-left-top {
  background-position: left top;
}

.bg-right {
  background-position: right;
}

.bg-right-bottom {
  background-position: right bottom;
}

.bg-right-top {
  background-position: right top;
}

.bg-top {
  background-position: top;
}

.bg-repeat {
  background-repeat: repeat;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.bg-repeat-x {
  background-repeat: repeat-x;
}

.bg-repeat-y {
  background-repeat: repeat-y;
}

.bg-repeat-round {
  background-repeat: round;
}

.bg-repeat-space {
  background-repeat: space;
}

.bg-auto {
  background-size: auto;
}

.bg-cover {
  background-size: cover;
}

.bg-contain {
  background-size: contain;
}

.border-transparent {
  border-color: transparent;
}

.border-grey2 {
  --border-opacity: 1;
  border-color: #1c252f;
  border-color: rgba(28, 37, 47, var(--border-opacity));
}

.border-grey5 {
  --border-opacity: 1;
  border-color: #9198a1;
  border-color: rgba(145, 152, 161, var(--border-opacity));
}

.border-grey6 {
  --border-opacity: 1;
  border-color: #c9ced4;
  border-color: rgba(201, 206, 212, var(--border-opacity));
}

.border-grey7 {
  --border-opacity: 1;
  border-color: #dfe2e6;
  border-color: rgba(223, 226, 230, var(--border-opacity));
}

.border-grey8 {
  --border-opacity: 1;
  border-color: #f0f2f5;
  border-color: rgba(240, 242, 245, var(--border-opacity));
}

.border-white {
  --border-opacity: 1;
  border-color: #fff;
  border-color: rgba(255, 255, 255, var(--border-opacity));
}

.border-blue {
  --border-opacity: 1;
  border-color: #569fe5;
  border-color: rgba(86, 159, 229, var(--border-opacity));
}

.border-red {
  --border-opacity: 1;
  border-color: #ed6c85;
  border-color: rgba(237, 108, 133, var(--border-opacity));
}

.border-socialiePink {
  --border-opacity: 1;
  border-color: #FC2361;
  border-color: rgba(252, 35, 97, var(--border-opacity));
}

.border-border {
  --border-opacity: 1;
  border-color: #dfe2e6;
  border-color: rgba(223, 226, 230, var(--border-opacity));
}

.border-greyBg {
  --border-opacity: 1;
  border-color: #f0f2f5;
  border-color: rgba(240, 242, 245, var(--border-opacity));
}

.hover\:border-socialiePinkHover:hover {
  --border-opacity: 1;
  border-color: #FF3D71;
  border-color: rgba(255, 61, 113, var(--border-opacity));
}

.focus\:border-white:focus {
  --border-opacity: 1;
  border-color: #fff;
  border-color: rgba(255, 255, 255, var(--border-opacity));
}

.rounded-16 {
  border-radius: 16px;
}

.rounded-sm {
  border-radius: 0.125rem;
}

.rounded {
  border-radius: 0.25rem;
}

.rounded-md {
  border-radius: 0.375rem;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-t-lg {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.border-solid {
  border-style: solid;
}

.border-0 {
  border-width: 0;
}

.border-2 {
  border-width: 2px;
}

.border {
  border-width: 1px;
}

.border-r-0 {
  border-right-width: 0;
}

.border-l-0 {
  border-left-width: 0;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-l-4 {
  border-left-width: 4px;
}

.border-t {
  border-top-width: 1px;
}

.border-r {
  border-right-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-l {
  border-left-width: 1px;
}

.last\:border-b-0:last-child {
  border-bottom-width: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.group:hover .group-hover\:block {
  display: block;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-no-wrap {
  flex-wrap: nowrap;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.float-right {
  float: right;
}

.font-sans {
  font-family: sofia-pro, sans-serif;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.h-0 {
  height: 0px;
}

.h-1 {
  height: 8px;
}

.h-2 {
  height: 16px;
}

.h-3 {
  height: 24px;
}

.h-4 {
  height: 32px;
}

.h-5 {
  height: 40px;
}

.h-6 {
  height: 48px;
}

.h-7 {
  height: 56px;
}

.h-8 {
  height: 64px;
}

.h-24 {
  height: 192px;
}

.h-40 {
  height: 320px;
}

.h-48 {
  height: 384px;
}

.h-full {
  height: 100%;
}

.h-1\.5 {
  height: 12px;
}

.h-1\.75 {
  height: 14px;
}

.h-2\.5 {
  height: 20px;
}

.h-3\.5 {
  height: 28px;
}

.h-4\.5 {
  height: 36px;
}

.h-5\.5 {
  height: 44px;
}

.h-screen {
  height: 100vh;
}

.text-12 {
  font-size: 12px;
}

.text-14 {
  font-size: 14px;
}

.text-16 {
  font-size: 16px;
}

.text-18 {
  font-size: 18px;
}

.text-20 {
  font-size: 20px;
}

.text-32 {
  font-size: 32px;
}

.text-54 {
  font-size: 54px;
}

.text-xxs {
  font-size: 10px;
}

.text-xs {
  font-size: 12px;
}

.text-sm {
  font-size: 14px;
}

.text-lg {
  font-size: 18px;
}

.text-xl {
  font-size: 22px;
}

.leading-68 {
  line-height: 68px;
}

.leading-none {
  line-height: 1;
}

.leading-tight {
  line-height: 1.25;
}

.m-1 {
  margin: 8px;
}

.m-2 {
  margin: 16px;
}

.-m-1 {
  margin: -8px;
}

.-m-px {
  margin: -1px;
}

.mx-1 {
  margin-left: 8px;
  margin-right: 8px;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mx-0\.5 {
  margin-left: 4px;
  margin-right: 4px;
}

.-mx-0\.5 {
  margin-left: -4px;
  margin-right: -4px;
}

.mb-0 {
  margin-bottom: 0px;
}

.mt-1 {
  margin-top: 8px;
}

.mr-1 {
  margin-right: 8px;
}

.mb-1 {
  margin-bottom: 8px;
}

.ml-1 {
  margin-left: 8px;
}

.mt-2 {
  margin-top: 16px;
}

.mr-2 {
  margin-right: 16px;
}

.mb-2 {
  margin-bottom: 16px;
}

.ml-2 {
  margin-left: 16px;
}

.mt-3 {
  margin-top: 24px;
}

.mr-3 {
  margin-right: 24px;
}

.mb-3 {
  margin-bottom: 24px;
}

.mt-4 {
  margin-top: 32px;
}

.mb-4 {
  margin-bottom: 32px;
}

.mb-6 {
  margin-bottom: 48px;
}

.mb-8 {
  margin-bottom: 64px;
}

.mt-9 {
  margin-top: 72px;
}

.mb-10 {
  margin-bottom: 80px;
}

.ml-auto {
  margin-left: auto;
}

.mt-0\.25 {
  margin-top: 2px;
}

.mr-0\.25 {
  margin-right: 2px;
}

.ml-0\.25 {
  margin-left: 2px;
}

.mt-0\.5 {
  margin-top: 4px;
}

.mr-0\.5 {
  margin-right: 4px;
}

.mb-0\.5 {
  margin-bottom: 4px;
}

.ml-0\.5 {
  margin-left: 4px;
}

.mt-0\.75 {
  margin-top: 6px;
}

.mr-0\.75 {
  margin-right: 6px;
}

.mb-0\.75 {
  margin-bottom: 6px;
}

.ml-0\.75 {
  margin-left: 6px;
}

.mr-1\.5 {
  margin-right: 12px;
}

.mb-1\.5 {
  margin-bottom: 12px;
}

.ml-1\.5 {
  margin-left: 12px;
}

.mb-2\.5 {
  margin-bottom: 20px;
}

.-mb-1 {
  margin-bottom: -8px;
}

.-mt-2 {
  margin-top: -16px;
}

.-mr-3 {
  margin-right: -24px;
}

.-mb-0\.25 {
  margin-bottom: -2px;
}

.-mr-0\.5 {
  margin-right: -4px;
}

.-mb-0\.5 {
  margin-bottom: -4px;
}

.-ml-0\.5 {
  margin-left: -4px;
}

.max-h-48 {
  max-height: 384px;
}

.min-h-full {
  min-height: 100%;
}

.min-h-screen {
  min-height: 100vh;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.opacity-0 {
  opacity: 0;
}

.opacity-25 {
  opacity: 0.25;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-75 {
  opacity: 0.75;
}

.opacity-92 {
  opacity: 0.92;
}

.opacity-100 {
  opacity: 1;
}

.hover\:opacity-100:hover {
  opacity: 1;
}

.disabled\:opacity-50:disabled {
  opacity: 0.5;
}

.group:hover .group-hover\:opacity-75 {
  opacity: 0.75;
}

.focus\:outline-none:focus {
  outline: 0;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.p-1 {
  padding: 8px;
}

.p-2 {
  padding: 16px;
}

.p-3 {
  padding: 24px;
}

.p-4 {
  padding: 32px;
}

.p-px {
  padding: 1px;
}

.p-0\.25 {
  padding: 2px;
}

.p-0\.5 {
  padding: 4px;
}

.py-1 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.px-1 {
  padding-left: 8px;
  padding-right: 8px;
}

.py-2 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.px-2 {
  padding-left: 16px;
  padding-right: 16px;
}

.py-3 {
  padding-top: 24px;
  padding-bottom: 24px;
}

.py-4 {
  padding-top: 32px;
  padding-bottom: 32px;
}

.px-4 {
  padding-left: 32px;
  padding-right: 32px;
}

.px-8 {
  padding-left: 64px;
  padding-right: 64px;
}

.py-0\.5 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.px-0\.5 {
  padding-left: 4px;
  padding-right: 4px;
}

.py-0\.75 {
  padding-top: 6px;
  padding-bottom: 6px;
}

.px-0\.75 {
  padding-left: 6px;
  padding-right: 6px;
}

.py-1\.5 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.px-1\.5 {
  padding-left: 12px;
  padding-right: 12px;
}

.px-2\.5 {
  padding-left: 20px;
  padding-right: 20px;
}

.px-4\.5 {
  padding-left: 36px;
  padding-right: 36px;
}

.pt-1 {
  padding-top: 8px;
}

.pr-1 {
  padding-right: 8px;
}

.pt-2 {
  padding-top: 16px;
}

.pr-2 {
  padding-right: 16px;
}

.pb-2 {
  padding-bottom: 16px;
}

.pr-3 {
  padding-right: 24px;
}

.pb-3 {
  padding-bottom: 24px;
}

.pt-4 {
  padding-top: 32px;
}

.pr-4 {
  padding-right: 32px;
}

.pb-4 {
  padding-bottom: 32px;
}

.pl-4 {
  padding-left: 32px;
}

.pt-8 {
  padding-top: 64px;
}

.pt-12 {
  padding-top: 96px;
}

.pl-32 {
  padding-left: 256px;
}

.pb-full {
  padding-bottom: 100%;
}

.pb-0\.75 {
  padding-bottom: 6px;
}

.pr-1\.5 {
  padding-right: 12px;
}

.pb-1\.5 {
  padding-bottom: 12px;
}

.pb-1\.75 {
  padding-bottom: 14px;
}

.pointer-events-none {
  pointer-events: none;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.shadow-sm {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.shadow {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.shadow-md {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.focus\:shadow-outline:focus {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}

.fill-current {
  fill: currentColor;
}

.stroke-2 {
  stroke-width: 2;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-grey1 {
  --text-opacity: 1;
  color: #101820;
  color: rgba(16, 24, 32, var(--text-opacity));
}

.text-grey2 {
  --text-opacity: 1;
  color: #1c252f;
  color: rgba(28, 37, 47, var(--text-opacity));
}

.text-grey4 {
  --text-opacity: 1;
  color: #5a636e;
  color: rgba(90, 99, 110, var(--text-opacity));
}

.text-grey5 {
  --text-opacity: 1;
  color: #9198a1;
  color: rgba(145, 152, 161, var(--text-opacity));
}

.text-grey6 {
  --text-opacity: 1;
  color: #c9ced4;
  color: rgba(201, 206, 212, var(--text-opacity));
}

.text-grey8 {
  --text-opacity: 1;
  color: #f0f2f5;
  color: rgba(240, 242, 245, var(--text-opacity));
}

.text-white {
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity));
}

.text-black {
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity));
}

.text-red {
  --text-opacity: 1;
  color: #ed6c85;
  color: rgba(237, 108, 133, var(--text-opacity));
}

.text-green {
  --text-opacity: 1;
  color: #4bc0c0;
  color: rgba(75, 192, 192, var(--text-opacity));
}

.text-error {
  --text-opacity: 1;
  color: #FF0000;
  color: rgba(255, 0, 0, var(--text-opacity));
}

.text-socialiePink {
  --text-opacity: 1;
  color: #FC2361;
  color: rgba(252, 35, 97, var(--text-opacity));
}

.text-socialieBlue {
  --text-opacity: 1;
  color: #5CC7EA;
  color: rgba(92, 199, 234, var(--text-opacity));
}

.text-bodyText {
  --text-opacity: 1;
  color: #5a636e;
  color: rgba(90, 99, 110, var(--text-opacity));
}

.text-darkText {
  --text-opacity: 1;
  color: #101820;
  color: rgba(16, 24, 32, var(--text-opacity));
}

.text-lightIcon {
  --text-opacity: 1;
  color: #c9ced4;
  color: rgba(201, 206, 212, var(--text-opacity));
}

.text-dark {
  --text-opacity: 1;
  color: #101820;
  color: rgba(16, 24, 32, var(--text-opacity));
}

.text-light {
  --text-opacity: 1;
  color: #9198a1;
  color: rgba(145, 152, 161, var(--text-opacity));
}

.hover\:text-grey2:hover {
  --text-opacity: 1;
  color: #1c252f;
  color: rgba(28, 37, 47, var(--text-opacity));
}

.hover\:text-white:hover {
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity));
}

.hover\:text-bodyText:hover {
  --text-opacity: 1;
  color: #5a636e;
  color: rgba(90, 99, 110, var(--text-opacity));
}

.focus\:text-socialieBlue:focus {
  --text-opacity: 1;
  color: #5CC7EA;
  color: rgba(92, 199, 234, var(--text-opacity));
}

.italic {
  font-style: italic;
}

.uppercase {
  text-transform: uppercase;
}

.underline {
  text-decoration: underline;
}

.no-underline {
  text-decoration: none;
}

.hover\:underline:hover {
  text-decoration: underline;
}

.hover\:no-underline:hover {
  text-decoration: none;
}

.tracking-11 {
  letter-spacing: .11;
}

.tracking-66 {
  letter-spacing: .66;
}

.tracking-200 {
  letter-spacing: 2.0;
}

.invisible {
  visibility: hidden;
}

.whitespace-no-wrap {
  white-space: nowrap;
}

.break-words {
  overflow-wrap: break-word;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.w-1 {
  width: 8px;
}

.w-2 {
  width: 16px;
}

.w-3 {
  width: 24px;
}

.w-5 {
  width: 40px;
}

.w-6 {
  width: 48px;
}

.w-8 {
  width: 64px;
}

.w-10 {
  width: 80px;
}

.w-17 {
  width: 136px;
}

.w-20 {
  width: 160px;
}

.w-24 {
  width: 192px;
}

.w-30 {
  width: 240px;
}

.w-32 {
  width: 256px;
}

.w-48 {
  width: 384px;
}

.w-auto {
  width: auto;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.333333%;
}

.w-1\/4 {
  width: 25%;
}

.w-full {
  width: 100%;
}

.w-1\.5 {
  width: 12px;
}

.w-1\.75 {
  width: 14px;
}

.w-2\.5 {
  width: 20px;
}

.w-3\.5 {
  width: 28px;
}

.w-4\.5 {
  width: 36px;
}

.w-22\.5 {
  width: 180px;
}

.w-51\.5 {
  width: 412px;
}

.w-3\/12 {
  width: 25%;
}

.w-9\/12 {
  width: 75%;
}

.w-screen {
  width: 100vw;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.gap-1 {
  grid-gap: 8px;
  gap: 8px;
}

.gap-2 {
  grid-gap: 16px;
  gap: 16px;
}

.gap-0\.5 {
  grid-gap: 4px;
  gap: 4px;
}

.gap-1\.5 {
  grid-gap: 12px;
  gap: 12px;
}

.grid-flow-col {
  grid-auto-flow: column;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.row-start-2 {
  grid-row-start: 2;
}

.transform {
  --transform-translate-x: 0;
  --transform-translate-y: 0;
  --transform-rotate: 0;
  --transform-skew-x: 0;
  --transform-skew-y: 0;
  --transform-scale-x: 1;
  --transform-scale-y: 1;
  -webkit-transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
          transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
}

.rotate-0 {
  --transform-rotate: 0;
}

.rotate-180 {
  --transform-rotate: 180deg;
}

.translate-x-2 {
  --transform-translate-x: 16px;
}

.transition-all {
  transition-property: all;
}

.transition-colors {
  transition-property: background-color, border-color, color, fill, stroke;
}

.transition-opacity {
  transition-property: opacity;
}

.transition-transform {
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.duration-150 {
  transition-duration: 150ms;
}

.duration-200 {
  transition-duration: 200ms;
}

.duration-300 {
  transition-duration: 300ms;
}

@media (min-width: 768px) {

  .tablet\:bg-fixed {
    background-attachment: fixed;
  }

  .tablet\:bg-local {
    background-attachment: local;
  }

  .tablet\:bg-scroll {
    background-attachment: scroll;
  }

  .tablet\:bg-transparent {
    background-color: transparent;
  }

  .tablet\:bg-grey1 {
    --bg-opacity: 1;
    background-color: #101820;
    background-color: rgba(16, 24, 32, var(--bg-opacity));
  }

  .tablet\:bg-grey2 {
    --bg-opacity: 1;
    background-color: #1c252f;
    background-color: rgba(28, 37, 47, var(--bg-opacity));
  }

  .tablet\:bg-grey3 {
    --bg-opacity: 1;
    background-color: #343d46;
    background-color: rgba(52, 61, 70, var(--bg-opacity));
  }

  .tablet\:bg-grey4 {
    --bg-opacity: 1;
    background-color: #5a636e;
    background-color: rgba(90, 99, 110, var(--bg-opacity));
  }

  .tablet\:bg-grey5 {
    --bg-opacity: 1;
    background-color: #9198a1;
    background-color: rgba(145, 152, 161, var(--bg-opacity));
  }

  .tablet\:bg-grey6 {
    --bg-opacity: 1;
    background-color: #c9ced4;
    background-color: rgba(201, 206, 212, var(--bg-opacity));
  }

  .tablet\:bg-grey7 {
    --bg-opacity: 1;
    background-color: #dfe2e6;
    background-color: rgba(223, 226, 230, var(--bg-opacity));
  }

  .tablet\:bg-grey8 {
    --bg-opacity: 1;
    background-color: #f0f2f5;
    background-color: rgba(240, 242, 245, var(--bg-opacity));
  }

  .tablet\:bg-grey9 {
    --bg-opacity: 1;
    background-color: #f5f7fa;
    background-color: rgba(245, 247, 250, var(--bg-opacity));
  }

  .tablet\:bg-white {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .tablet\:bg-black {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity));
  }

  .tablet\:bg-lightTeal {
    --bg-opacity: 1;
    background-color: #edf8f8;
    background-color: rgba(237, 248, 248, var(--bg-opacity));
  }

  .tablet\:bg-teal {
    --bg-opacity: 1;
    background-color: #46b4b4;
    background-color: rgba(70, 180, 180, var(--bg-opacity));
  }

  .tablet\:bg-darkTeal {
    --bg-opacity: 1;
    background-color: #46b4b4;
    background-color: rgba(70, 180, 180, var(--bg-opacity));
  }

  .tablet\:bg-tealBg {
    --bg-opacity: 1;
    background-color: #eff8f8;
    background-color: rgba(239, 248, 248, var(--bg-opacity));
  }

  .tablet\:bg-yellow {
    --bg-opacity: 1;
    background-color: #ff9f40;
    background-color: rgba(255, 159, 64, var(--bg-opacity));
  }

  .tablet\:bg-yellowBg {
    --bg-opacity: 1;
    background-color: #fff5eb;
    background-color: rgba(255, 245, 235, var(--bg-opacity));
  }

  .tablet\:bg-blue {
    --bg-opacity: 1;
    background-color: #569fe5;
    background-color: rgba(86, 159, 229, var(--bg-opacity));
  }

  .tablet\:bg-blueBg {
    --bg-opacity: 1;
    background-color: #ecf6fd;
    background-color: rgba(236, 246, 253, var(--bg-opacity));
  }

  .tablet\:bg-red {
    --bg-opacity: 1;
    background-color: #ed6c85;
    background-color: rgba(237, 108, 133, var(--bg-opacity));
  }

  .tablet\:bg-redBg {
    --bg-opacity: 1;
    background-color: #fdeff2;
    background-color: rgba(253, 239, 242, var(--bg-opacity));
  }

  .tablet\:bg-green {
    --bg-opacity: 1;
    background-color: #4bc0c0;
    background-color: rgba(75, 192, 192, var(--bg-opacity));
  }

  .tablet\:bg-error {
    --bg-opacity: 1;
    background-color: #FF0000;
    background-color: rgba(255, 0, 0, var(--bg-opacity));
  }

  .tablet\:bg-errorBg {
    --bg-opacity: 1;
    background-color: #ffeded;
    background-color: rgba(255, 237, 237, var(--bg-opacity));
  }

  .tablet\:bg-facebook {
    --bg-opacity: 1;
    background-color: #1877f2;
    background-color: rgba(24, 119, 242, var(--bg-opacity));
  }

  .tablet\:bg-instagram {
    --bg-opacity: 1;
    background-color: #e73558;
    background-color: rgba(231, 53, 88, var(--bg-opacity));
  }

  .tablet\:bg-instagram_basic {
    --bg-opacity: 1;
    background-color: #e73558;
    background-color: rgba(231, 53, 88, var(--bg-opacity));
  }

  .tablet\:bg-instagram_graph {
    --bg-opacity: 1;
    background-color: #e73558;
    background-color: rgba(231, 53, 88, var(--bg-opacity));
  }

  .tablet\:bg-linkedin {
    --bg-opacity: 1;
    background-color: #1b73b7;
    background-color: rgba(27, 115, 183, var(--bg-opacity));
  }

  .tablet\:bg-twitter {
    --bg-opacity: 1;
    background-color: #52a8f0;
    background-color: rgba(82, 168, 240, var(--bg-opacity));
  }

  .tablet\:bg-whatsAppGreen {
    --bg-opacity: 1;
    background-color: #25D366;
    background-color: rgba(37, 211, 102, var(--bg-opacity));
  }

  .tablet\:bg-socialiePink {
    --bg-opacity: 1;
    background-color: #FC2361;
    background-color: rgba(252, 35, 97, var(--bg-opacity));
  }

  .tablet\:bg-socialiePinkHover {
    --bg-opacity: 1;
    background-color: #FF3D71;
    background-color: rgba(255, 61, 113, var(--bg-opacity));
  }

  .tablet\:bg-socialieBlue {
    --bg-opacity: 1;
    background-color: #5CC7EA;
    background-color: rgba(92, 199, 234, var(--bg-opacity));
  }

  .tablet\:bg-badgeBlue {
    --bg-opacity: 1;
    background-color: #1c90ff;
    background-color: rgba(28, 144, 255, var(--bg-opacity));
  }

  .tablet\:bg-success {
    --bg-opacity: 1;
    background-color: #97cc04;
    background-color: rgba(151, 204, 4, var(--bg-opacity));
  }

  .tablet\:bg-bodyText {
    --bg-opacity: 1;
    background-color: #5a636e;
    background-color: rgba(90, 99, 110, var(--bg-opacity));
  }

  .tablet\:bg-bodyBg {
    --bg-opacity: 1;
    background-color: #f5f7fa;
    background-color: rgba(245, 247, 250, var(--bg-opacity));
  }

  .tablet\:bg-border {
    --bg-opacity: 1;
    background-color: #dfe2e6;
    background-color: rgba(223, 226, 230, var(--bg-opacity));
  }

  .tablet\:bg-boxShadow {
    background-color: rgba(0, 0, 0, 0.12);
  }

  .tablet\:bg-darkBorder {
    --bg-opacity: 1;
    background-color: #c9ced4;
    background-color: rgba(201, 206, 212, var(--bg-opacity));
  }

  .tablet\:bg-darkerBorder {
    --bg-opacity: 1;
    background-color: #5a636e;
    background-color: rgba(90, 99, 110, var(--bg-opacity));
  }

  .tablet\:bg-darkestGreyBg {
    --bg-opacity: 1;
    background-color: #5a636e;
    background-color: rgba(90, 99, 110, var(--bg-opacity));
  }

  .tablet\:bg-darkGreyBg {
    --bg-opacity: 1;
    background-color: #c9ced4;
    background-color: rgba(201, 206, 212, var(--bg-opacity));
  }

  .tablet\:bg-darkText {
    --bg-opacity: 1;
    background-color: #101820;
    background-color: rgba(16, 24, 32, var(--bg-opacity));
  }

  .tablet\:bg-draftBuilderBg {
    --bg-opacity: 1;
    background-color: #1c252f;
    background-color: rgba(28, 37, 47, var(--bg-opacity));
  }

  .tablet\:bg-semiBlack {
    background-color: rgba(0, 0, 0, 0.8);
  }

  .tablet\:bg-semiWhite {
    background-color: rgba(255, 255, 255, 0.8);
  }

  .tablet\:bg-focus {
    --bg-opacity: 1;
    background-color: #c9ced4;
    background-color: rgba(201, 206, 212, var(--bg-opacity));
  }

  .tablet\:bg-grey {
    --bg-opacity: 1;
    background-color: #5a636e;
    background-color: rgba(90, 99, 110, var(--bg-opacity));
  }

  .tablet\:bg-greyBg {
    --bg-opacity: 1;
    background-color: #f0f2f5;
    background-color: rgba(240, 242, 245, var(--bg-opacity));
  }

  .tablet\:bg-hoverBg {
    background-color: rgba(240,242,245,0.25);
  }

  .tablet\:bg-inverseBorder {
    --bg-opacity: 1;
    background-color: #343d46;
    background-color: rgba(52, 61, 70, var(--bg-opacity));
  }

  .tablet\:bg-messageBg {
    --bg-opacity: 1;
    background-color: #36a2eb;
    background-color: rgba(54, 162, 235, var(--bg-opacity));
  }

  .tablet\:bg-lightBorder {
    --bg-opacity: 1;
    background-color: #f0f2f5;
    background-color: rgba(240, 242, 245, var(--bg-opacity));
  }

  .tablet\:bg-lighterText {
    --bg-opacity: 1;
    background-color: #c9ced4;
    background-color: rgba(201, 206, 212, var(--bg-opacity));
  }

  .tablet\:bg-lightIcon {
    --bg-opacity: 1;
    background-color: #c9ced4;
    background-color: rgba(201, 206, 212, var(--bg-opacity));
  }

  .tablet\:bg-lightText {
    --bg-opacity: 1;
    background-color: #9198a1;
    background-color: rgba(145, 152, 161, var(--bg-opacity));
  }

  .tablet\:bg-link {
    --bg-opacity: 1;
    background-color: #569fe5;
    background-color: rgba(86, 159, 229, var(--bg-opacity));
  }

  .tablet\:bg-default {
    --bg-opacity: 1;
    background-color: #5a636e;
    background-color: rgba(90, 99, 110, var(--bg-opacity));
  }

  .tablet\:hover\:bg-transparent:hover {
    background-color: transparent;
  }

  .tablet\:hover\:bg-grey1:hover {
    --bg-opacity: 1;
    background-color: #101820;
    background-color: rgba(16, 24, 32, var(--bg-opacity));
  }

  .tablet\:hover\:bg-grey2:hover {
    --bg-opacity: 1;
    background-color: #1c252f;
    background-color: rgba(28, 37, 47, var(--bg-opacity));
  }

  .tablet\:hover\:bg-grey3:hover {
    --bg-opacity: 1;
    background-color: #343d46;
    background-color: rgba(52, 61, 70, var(--bg-opacity));
  }

  .tablet\:hover\:bg-grey4:hover {
    --bg-opacity: 1;
    background-color: #5a636e;
    background-color: rgba(90, 99, 110, var(--bg-opacity));
  }

  .tablet\:hover\:bg-grey5:hover {
    --bg-opacity: 1;
    background-color: #9198a1;
    background-color: rgba(145, 152, 161, var(--bg-opacity));
  }

  .tablet\:hover\:bg-grey6:hover {
    --bg-opacity: 1;
    background-color: #c9ced4;
    background-color: rgba(201, 206, 212, var(--bg-opacity));
  }

  .tablet\:hover\:bg-grey7:hover {
    --bg-opacity: 1;
    background-color: #dfe2e6;
    background-color: rgba(223, 226, 230, var(--bg-opacity));
  }

  .tablet\:hover\:bg-grey8:hover {
    --bg-opacity: 1;
    background-color: #f0f2f5;
    background-color: rgba(240, 242, 245, var(--bg-opacity));
  }

  .tablet\:hover\:bg-grey9:hover {
    --bg-opacity: 1;
    background-color: #f5f7fa;
    background-color: rgba(245, 247, 250, var(--bg-opacity));
  }

  .tablet\:hover\:bg-white:hover {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .tablet\:hover\:bg-black:hover {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity));
  }

  .tablet\:hover\:bg-lightTeal:hover {
    --bg-opacity: 1;
    background-color: #edf8f8;
    background-color: rgba(237, 248, 248, var(--bg-opacity));
  }

  .tablet\:hover\:bg-teal:hover {
    --bg-opacity: 1;
    background-color: #46b4b4;
    background-color: rgba(70, 180, 180, var(--bg-opacity));
  }

  .tablet\:hover\:bg-darkTeal:hover {
    --bg-opacity: 1;
    background-color: #46b4b4;
    background-color: rgba(70, 180, 180, var(--bg-opacity));
  }

  .tablet\:hover\:bg-tealBg:hover {
    --bg-opacity: 1;
    background-color: #eff8f8;
    background-color: rgba(239, 248, 248, var(--bg-opacity));
  }

  .tablet\:hover\:bg-yellow:hover {
    --bg-opacity: 1;
    background-color: #ff9f40;
    background-color: rgba(255, 159, 64, var(--bg-opacity));
  }

  .tablet\:hover\:bg-yellowBg:hover {
    --bg-opacity: 1;
    background-color: #fff5eb;
    background-color: rgba(255, 245, 235, var(--bg-opacity));
  }

  .tablet\:hover\:bg-blue:hover {
    --bg-opacity: 1;
    background-color: #569fe5;
    background-color: rgba(86, 159, 229, var(--bg-opacity));
  }

  .tablet\:hover\:bg-blueBg:hover {
    --bg-opacity: 1;
    background-color: #ecf6fd;
    background-color: rgba(236, 246, 253, var(--bg-opacity));
  }

  .tablet\:hover\:bg-red:hover {
    --bg-opacity: 1;
    background-color: #ed6c85;
    background-color: rgba(237, 108, 133, var(--bg-opacity));
  }

  .tablet\:hover\:bg-redBg:hover {
    --bg-opacity: 1;
    background-color: #fdeff2;
    background-color: rgba(253, 239, 242, var(--bg-opacity));
  }

  .tablet\:hover\:bg-green:hover {
    --bg-opacity: 1;
    background-color: #4bc0c0;
    background-color: rgba(75, 192, 192, var(--bg-opacity));
  }

  .tablet\:hover\:bg-error:hover {
    --bg-opacity: 1;
    background-color: #FF0000;
    background-color: rgba(255, 0, 0, var(--bg-opacity));
  }

  .tablet\:hover\:bg-errorBg:hover {
    --bg-opacity: 1;
    background-color: #ffeded;
    background-color: rgba(255, 237, 237, var(--bg-opacity));
  }

  .tablet\:hover\:bg-facebook:hover {
    --bg-opacity: 1;
    background-color: #1877f2;
    background-color: rgba(24, 119, 242, var(--bg-opacity));
  }

  .tablet\:hover\:bg-instagram:hover {
    --bg-opacity: 1;
    background-color: #e73558;
    background-color: rgba(231, 53, 88, var(--bg-opacity));
  }

  .tablet\:hover\:bg-instagram_basic:hover {
    --bg-opacity: 1;
    background-color: #e73558;
    background-color: rgba(231, 53, 88, var(--bg-opacity));
  }

  .tablet\:hover\:bg-instagram_graph:hover {
    --bg-opacity: 1;
    background-color: #e73558;
    background-color: rgba(231, 53, 88, var(--bg-opacity));
  }

  .tablet\:hover\:bg-linkedin:hover {
    --bg-opacity: 1;
    background-color: #1b73b7;
    background-color: rgba(27, 115, 183, var(--bg-opacity));
  }

  .tablet\:hover\:bg-twitter:hover {
    --bg-opacity: 1;
    background-color: #52a8f0;
    background-color: rgba(82, 168, 240, var(--bg-opacity));
  }

  .tablet\:hover\:bg-whatsAppGreen:hover {
    --bg-opacity: 1;
    background-color: #25D366;
    background-color: rgba(37, 211, 102, var(--bg-opacity));
  }

  .tablet\:hover\:bg-socialiePink:hover {
    --bg-opacity: 1;
    background-color: #FC2361;
    background-color: rgba(252, 35, 97, var(--bg-opacity));
  }

  .tablet\:hover\:bg-socialiePinkHover:hover {
    --bg-opacity: 1;
    background-color: #FF3D71;
    background-color: rgba(255, 61, 113, var(--bg-opacity));
  }

  .tablet\:hover\:bg-socialieBlue:hover {
    --bg-opacity: 1;
    background-color: #5CC7EA;
    background-color: rgba(92, 199, 234, var(--bg-opacity));
  }

  .tablet\:hover\:bg-badgeBlue:hover {
    --bg-opacity: 1;
    background-color: #1c90ff;
    background-color: rgba(28, 144, 255, var(--bg-opacity));
  }

  .tablet\:hover\:bg-success:hover {
    --bg-opacity: 1;
    background-color: #97cc04;
    background-color: rgba(151, 204, 4, var(--bg-opacity));
  }

  .tablet\:hover\:bg-bodyText:hover {
    --bg-opacity: 1;
    background-color: #5a636e;
    background-color: rgba(90, 99, 110, var(--bg-opacity));
  }

  .tablet\:hover\:bg-bodyBg:hover {
    --bg-opacity: 1;
    background-color: #f5f7fa;
    background-color: rgba(245, 247, 250, var(--bg-opacity));
  }

  .tablet\:hover\:bg-border:hover {
    --bg-opacity: 1;
    background-color: #dfe2e6;
    background-color: rgba(223, 226, 230, var(--bg-opacity));
  }

  .tablet\:hover\:bg-boxShadow:hover {
    background-color: rgba(0, 0, 0, 0.12);
  }

  .tablet\:hover\:bg-darkBorder:hover {
    --bg-opacity: 1;
    background-color: #c9ced4;
    background-color: rgba(201, 206, 212, var(--bg-opacity));
  }

  .tablet\:hover\:bg-darkerBorder:hover {
    --bg-opacity: 1;
    background-color: #5a636e;
    background-color: rgba(90, 99, 110, var(--bg-opacity));
  }

  .tablet\:hover\:bg-darkestGreyBg:hover {
    --bg-opacity: 1;
    background-color: #5a636e;
    background-color: rgba(90, 99, 110, var(--bg-opacity));
  }

  .tablet\:hover\:bg-darkGreyBg:hover {
    --bg-opacity: 1;
    background-color: #c9ced4;
    background-color: rgba(201, 206, 212, var(--bg-opacity));
  }

  .tablet\:hover\:bg-darkText:hover {
    --bg-opacity: 1;
    background-color: #101820;
    background-color: rgba(16, 24, 32, var(--bg-opacity));
  }

  .tablet\:hover\:bg-draftBuilderBg:hover {
    --bg-opacity: 1;
    background-color: #1c252f;
    background-color: rgba(28, 37, 47, var(--bg-opacity));
  }

  .tablet\:hover\:bg-semiBlack:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }

  .tablet\:hover\:bg-semiWhite:hover {
    background-color: rgba(255, 255, 255, 0.8);
  }

  .tablet\:hover\:bg-focus:hover {
    --bg-opacity: 1;
    background-color: #c9ced4;
    background-color: rgba(201, 206, 212, var(--bg-opacity));
  }

  .tablet\:hover\:bg-grey:hover {
    --bg-opacity: 1;
    background-color: #5a636e;
    background-color: rgba(90, 99, 110, var(--bg-opacity));
  }

  .tablet\:hover\:bg-greyBg:hover {
    --bg-opacity: 1;
    background-color: #f0f2f5;
    background-color: rgba(240, 242, 245, var(--bg-opacity));
  }

  .tablet\:hover\:bg-hoverBg:hover {
    background-color: rgba(240,242,245,0.25);
  }

  .tablet\:hover\:bg-inverseBorder:hover {
    --bg-opacity: 1;
    background-color: #343d46;
    background-color: rgba(52, 61, 70, var(--bg-opacity));
  }

  .tablet\:hover\:bg-messageBg:hover {
    --bg-opacity: 1;
    background-color: #36a2eb;
    background-color: rgba(54, 162, 235, var(--bg-opacity));
  }

  .tablet\:hover\:bg-lightBorder:hover {
    --bg-opacity: 1;
    background-color: #f0f2f5;
    background-color: rgba(240, 242, 245, var(--bg-opacity));
  }

  .tablet\:hover\:bg-lighterText:hover {
    --bg-opacity: 1;
    background-color: #c9ced4;
    background-color: rgba(201, 206, 212, var(--bg-opacity));
  }

  .tablet\:hover\:bg-lightIcon:hover {
    --bg-opacity: 1;
    background-color: #c9ced4;
    background-color: rgba(201, 206, 212, var(--bg-opacity));
  }

  .tablet\:hover\:bg-lightText:hover {
    --bg-opacity: 1;
    background-color: #9198a1;
    background-color: rgba(145, 152, 161, var(--bg-opacity));
  }

  .tablet\:hover\:bg-link:hover {
    --bg-opacity: 1;
    background-color: #569fe5;
    background-color: rgba(86, 159, 229, var(--bg-opacity));
  }

  .tablet\:hover\:bg-default:hover {
    --bg-opacity: 1;
    background-color: #5a636e;
    background-color: rgba(90, 99, 110, var(--bg-opacity));
  }

  .tablet\:focus\:bg-transparent:focus {
    background-color: transparent;
  }

  .tablet\:focus\:bg-grey1:focus {
    --bg-opacity: 1;
    background-color: #101820;
    background-color: rgba(16, 24, 32, var(--bg-opacity));
  }

  .tablet\:focus\:bg-grey2:focus {
    --bg-opacity: 1;
    background-color: #1c252f;
    background-color: rgba(28, 37, 47, var(--bg-opacity));
  }

  .tablet\:focus\:bg-grey3:focus {
    --bg-opacity: 1;
    background-color: #343d46;
    background-color: rgba(52, 61, 70, var(--bg-opacity));
  }

  .tablet\:focus\:bg-grey4:focus {
    --bg-opacity: 1;
    background-color: #5a636e;
    background-color: rgba(90, 99, 110, var(--bg-opacity));
  }

  .tablet\:focus\:bg-grey5:focus {
    --bg-opacity: 1;
    background-color: #9198a1;
    background-color: rgba(145, 152, 161, var(--bg-opacity));
  }

  .tablet\:focus\:bg-grey6:focus {
    --bg-opacity: 1;
    background-color: #c9ced4;
    background-color: rgba(201, 206, 212, var(--bg-opacity));
  }

  .tablet\:focus\:bg-grey7:focus {
    --bg-opacity: 1;
    background-color: #dfe2e6;
    background-color: rgba(223, 226, 230, var(--bg-opacity));
  }

  .tablet\:focus\:bg-grey8:focus {
    --bg-opacity: 1;
    background-color: #f0f2f5;
    background-color: rgba(240, 242, 245, var(--bg-opacity));
  }

  .tablet\:focus\:bg-grey9:focus {
    --bg-opacity: 1;
    background-color: #f5f7fa;
    background-color: rgba(245, 247, 250, var(--bg-opacity));
  }

  .tablet\:focus\:bg-white:focus {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .tablet\:focus\:bg-black:focus {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity));
  }

  .tablet\:focus\:bg-lightTeal:focus {
    --bg-opacity: 1;
    background-color: #edf8f8;
    background-color: rgba(237, 248, 248, var(--bg-opacity));
  }

  .tablet\:focus\:bg-teal:focus {
    --bg-opacity: 1;
    background-color: #46b4b4;
    background-color: rgba(70, 180, 180, var(--bg-opacity));
  }

  .tablet\:focus\:bg-darkTeal:focus {
    --bg-opacity: 1;
    background-color: #46b4b4;
    background-color: rgba(70, 180, 180, var(--bg-opacity));
  }

  .tablet\:focus\:bg-tealBg:focus {
    --bg-opacity: 1;
    background-color: #eff8f8;
    background-color: rgba(239, 248, 248, var(--bg-opacity));
  }

  .tablet\:focus\:bg-yellow:focus {
    --bg-opacity: 1;
    background-color: #ff9f40;
    background-color: rgba(255, 159, 64, var(--bg-opacity));
  }

  .tablet\:focus\:bg-yellowBg:focus {
    --bg-opacity: 1;
    background-color: #fff5eb;
    background-color: rgba(255, 245, 235, var(--bg-opacity));
  }

  .tablet\:focus\:bg-blue:focus {
    --bg-opacity: 1;
    background-color: #569fe5;
    background-color: rgba(86, 159, 229, var(--bg-opacity));
  }

  .tablet\:focus\:bg-blueBg:focus {
    --bg-opacity: 1;
    background-color: #ecf6fd;
    background-color: rgba(236, 246, 253, var(--bg-opacity));
  }

  .tablet\:focus\:bg-red:focus {
    --bg-opacity: 1;
    background-color: #ed6c85;
    background-color: rgba(237, 108, 133, var(--bg-opacity));
  }

  .tablet\:focus\:bg-redBg:focus {
    --bg-opacity: 1;
    background-color: #fdeff2;
    background-color: rgba(253, 239, 242, var(--bg-opacity));
  }

  .tablet\:focus\:bg-green:focus {
    --bg-opacity: 1;
    background-color: #4bc0c0;
    background-color: rgba(75, 192, 192, var(--bg-opacity));
  }

  .tablet\:focus\:bg-error:focus {
    --bg-opacity: 1;
    background-color: #FF0000;
    background-color: rgba(255, 0, 0, var(--bg-opacity));
  }

  .tablet\:focus\:bg-errorBg:focus {
    --bg-opacity: 1;
    background-color: #ffeded;
    background-color: rgba(255, 237, 237, var(--bg-opacity));
  }

  .tablet\:focus\:bg-facebook:focus {
    --bg-opacity: 1;
    background-color: #1877f2;
    background-color: rgba(24, 119, 242, var(--bg-opacity));
  }

  .tablet\:focus\:bg-instagram:focus {
    --bg-opacity: 1;
    background-color: #e73558;
    background-color: rgba(231, 53, 88, var(--bg-opacity));
  }

  .tablet\:focus\:bg-instagram_basic:focus {
    --bg-opacity: 1;
    background-color: #e73558;
    background-color: rgba(231, 53, 88, var(--bg-opacity));
  }

  .tablet\:focus\:bg-instagram_graph:focus {
    --bg-opacity: 1;
    background-color: #e73558;
    background-color: rgba(231, 53, 88, var(--bg-opacity));
  }

  .tablet\:focus\:bg-linkedin:focus {
    --bg-opacity: 1;
    background-color: #1b73b7;
    background-color: rgba(27, 115, 183, var(--bg-opacity));
  }

  .tablet\:focus\:bg-twitter:focus {
    --bg-opacity: 1;
    background-color: #52a8f0;
    background-color: rgba(82, 168, 240, var(--bg-opacity));
  }

  .tablet\:focus\:bg-whatsAppGreen:focus {
    --bg-opacity: 1;
    background-color: #25D366;
    background-color: rgba(37, 211, 102, var(--bg-opacity));
  }

  .tablet\:focus\:bg-socialiePink:focus {
    --bg-opacity: 1;
    background-color: #FC2361;
    background-color: rgba(252, 35, 97, var(--bg-opacity));
  }

  .tablet\:focus\:bg-socialiePinkHover:focus {
    --bg-opacity: 1;
    background-color: #FF3D71;
    background-color: rgba(255, 61, 113, var(--bg-opacity));
  }

  .tablet\:focus\:bg-socialieBlue:focus {
    --bg-opacity: 1;
    background-color: #5CC7EA;
    background-color: rgba(92, 199, 234, var(--bg-opacity));
  }

  .tablet\:focus\:bg-badgeBlue:focus {
    --bg-opacity: 1;
    background-color: #1c90ff;
    background-color: rgba(28, 144, 255, var(--bg-opacity));
  }

  .tablet\:focus\:bg-success:focus {
    --bg-opacity: 1;
    background-color: #97cc04;
    background-color: rgba(151, 204, 4, var(--bg-opacity));
  }

  .tablet\:focus\:bg-bodyText:focus {
    --bg-opacity: 1;
    background-color: #5a636e;
    background-color: rgba(90, 99, 110, var(--bg-opacity));
  }

  .tablet\:focus\:bg-bodyBg:focus {
    --bg-opacity: 1;
    background-color: #f5f7fa;
    background-color: rgba(245, 247, 250, var(--bg-opacity));
  }

  .tablet\:focus\:bg-border:focus {
    --bg-opacity: 1;
    background-color: #dfe2e6;
    background-color: rgba(223, 226, 230, var(--bg-opacity));
  }

  .tablet\:focus\:bg-boxShadow:focus {
    background-color: rgba(0, 0, 0, 0.12);
  }

  .tablet\:focus\:bg-darkBorder:focus {
    --bg-opacity: 1;
    background-color: #c9ced4;
    background-color: rgba(201, 206, 212, var(--bg-opacity));
  }

  .tablet\:focus\:bg-darkerBorder:focus {
    --bg-opacity: 1;
    background-color: #5a636e;
    background-color: rgba(90, 99, 110, var(--bg-opacity));
  }

  .tablet\:focus\:bg-darkestGreyBg:focus {
    --bg-opacity: 1;
    background-color: #5a636e;
    background-color: rgba(90, 99, 110, var(--bg-opacity));
  }

  .tablet\:focus\:bg-darkGreyBg:focus {
    --bg-opacity: 1;
    background-color: #c9ced4;
    background-color: rgba(201, 206, 212, var(--bg-opacity));
  }

  .tablet\:focus\:bg-darkText:focus {
    --bg-opacity: 1;
    background-color: #101820;
    background-color: rgba(16, 24, 32, var(--bg-opacity));
  }

  .tablet\:focus\:bg-draftBuilderBg:focus {
    --bg-opacity: 1;
    background-color: #1c252f;
    background-color: rgba(28, 37, 47, var(--bg-opacity));
  }

  .tablet\:focus\:bg-semiBlack:focus {
    background-color: rgba(0, 0, 0, 0.8);
  }

  .tablet\:focus\:bg-semiWhite:focus {
    background-color: rgba(255, 255, 255, 0.8);
  }

  .tablet\:focus\:bg-focus:focus {
    --bg-opacity: 1;
    background-color: #c9ced4;
    background-color: rgba(201, 206, 212, var(--bg-opacity));
  }

  .tablet\:focus\:bg-grey:focus {
    --bg-opacity: 1;
    background-color: #5a636e;
    background-color: rgba(90, 99, 110, var(--bg-opacity));
  }

  .tablet\:focus\:bg-greyBg:focus {
    --bg-opacity: 1;
    background-color: #f0f2f5;
    background-color: rgba(240, 242, 245, var(--bg-opacity));
  }

  .tablet\:focus\:bg-hoverBg:focus {
    background-color: rgba(240,242,245,0.25);
  }

  .tablet\:focus\:bg-inverseBorder:focus {
    --bg-opacity: 1;
    background-color: #343d46;
    background-color: rgba(52, 61, 70, var(--bg-opacity));
  }

  .tablet\:focus\:bg-messageBg:focus {
    --bg-opacity: 1;
    background-color: #36a2eb;
    background-color: rgba(54, 162, 235, var(--bg-opacity));
  }

  .tablet\:focus\:bg-lightBorder:focus {
    --bg-opacity: 1;
    background-color: #f0f2f5;
    background-color: rgba(240, 242, 245, var(--bg-opacity));
  }

  .tablet\:focus\:bg-lighterText:focus {
    --bg-opacity: 1;
    background-color: #c9ced4;
    background-color: rgba(201, 206, 212, var(--bg-opacity));
  }

  .tablet\:focus\:bg-lightIcon:focus {
    --bg-opacity: 1;
    background-color: #c9ced4;
    background-color: rgba(201, 206, 212, var(--bg-opacity));
  }

  .tablet\:focus\:bg-lightText:focus {
    --bg-opacity: 1;
    background-color: #9198a1;
    background-color: rgba(145, 152, 161, var(--bg-opacity));
  }

  .tablet\:focus\:bg-link:focus {
    --bg-opacity: 1;
    background-color: #569fe5;
    background-color: rgba(86, 159, 229, var(--bg-opacity));
  }

  .tablet\:focus\:bg-default:focus {
    --bg-opacity: 1;
    background-color: #5a636e;
    background-color: rgba(90, 99, 110, var(--bg-opacity));
  }

  .tablet\:bg-opacity-0 {
    --bg-opacity: 0;
  }

  .tablet\:bg-opacity-10 {
    --bg-opacity: 0.1;
  }

  .tablet\:bg-opacity-25 {
    --bg-opacity: 0.25;
  }

  .tablet\:bg-opacity-50 {
    --bg-opacity: 0.5;
  }

  .tablet\:bg-opacity-75 {
    --bg-opacity: 0.75;
  }

  .tablet\:bg-opacity-92 {
    --bg-opacity: 0.92px;
  }

  .tablet\:bg-opacity-100 {
    --bg-opacity: 1;
  }

  .tablet\:hover\:bg-opacity-0:hover {
    --bg-opacity: 0;
  }

  .tablet\:hover\:bg-opacity-10:hover {
    --bg-opacity: 0.1;
  }

  .tablet\:hover\:bg-opacity-25:hover {
    --bg-opacity: 0.25;
  }

  .tablet\:hover\:bg-opacity-50:hover {
    --bg-opacity: 0.5;
  }

  .tablet\:hover\:bg-opacity-75:hover {
    --bg-opacity: 0.75;
  }

  .tablet\:hover\:bg-opacity-92:hover {
    --bg-opacity: 0.92px;
  }

  .tablet\:hover\:bg-opacity-100:hover {
    --bg-opacity: 1;
  }

  .tablet\:focus\:bg-opacity-0:focus {
    --bg-opacity: 0;
  }

  .tablet\:focus\:bg-opacity-10:focus {
    --bg-opacity: 0.1;
  }

  .tablet\:focus\:bg-opacity-25:focus {
    --bg-opacity: 0.25;
  }

  .tablet\:focus\:bg-opacity-50:focus {
    --bg-opacity: 0.5;
  }

  .tablet\:focus\:bg-opacity-75:focus {
    --bg-opacity: 0.75;
  }

  .tablet\:focus\:bg-opacity-92:focus {
    --bg-opacity: 0.92px;
  }

  .tablet\:focus\:bg-opacity-100:focus {
    --bg-opacity: 1;
  }

  .tablet\:bg-bottom {
    background-position: bottom;
  }

  .tablet\:bg-center {
    background-position: center;
  }

  .tablet\:bg-left {
    background-position: left;
  }

  .tablet\:bg-left-bottom {
    background-position: left bottom;
  }

  .tablet\:bg-left-top {
    background-position: left top;
  }

  .tablet\:bg-right {
    background-position: right;
  }

  .tablet\:bg-right-bottom {
    background-position: right bottom;
  }

  .tablet\:bg-right-top {
    background-position: right top;
  }

  .tablet\:bg-top {
    background-position: top;
  }

  .tablet\:bg-repeat {
    background-repeat: repeat;
  }

  .tablet\:bg-no-repeat {
    background-repeat: no-repeat;
  }

  .tablet\:bg-repeat-x {
    background-repeat: repeat-x;
  }

  .tablet\:bg-repeat-y {
    background-repeat: repeat-y;
  }

  .tablet\:bg-repeat-round {
    background-repeat: round;
  }

  .tablet\:bg-repeat-space {
    background-repeat: space;
  }

  .tablet\:bg-auto {
    background-size: auto;
  }

  .tablet\:bg-cover {
    background-size: cover;
  }

  .tablet\:bg-contain {
    background-size: contain;
  }
}

@media (min-width: 1170px) {

  .desktop\:bg-fixed {
    background-attachment: fixed;
  }

  .desktop\:bg-local {
    background-attachment: local;
  }

  .desktop\:bg-scroll {
    background-attachment: scroll;
  }

  .desktop\:bg-transparent {
    background-color: transparent;
  }

  .desktop\:bg-grey1 {
    --bg-opacity: 1;
    background-color: #101820;
    background-color: rgba(16, 24, 32, var(--bg-opacity));
  }

  .desktop\:bg-grey2 {
    --bg-opacity: 1;
    background-color: #1c252f;
    background-color: rgba(28, 37, 47, var(--bg-opacity));
  }

  .desktop\:bg-grey3 {
    --bg-opacity: 1;
    background-color: #343d46;
    background-color: rgba(52, 61, 70, var(--bg-opacity));
  }

  .desktop\:bg-grey4 {
    --bg-opacity: 1;
    background-color: #5a636e;
    background-color: rgba(90, 99, 110, var(--bg-opacity));
  }

  .desktop\:bg-grey5 {
    --bg-opacity: 1;
    background-color: #9198a1;
    background-color: rgba(145, 152, 161, var(--bg-opacity));
  }

  .desktop\:bg-grey6 {
    --bg-opacity: 1;
    background-color: #c9ced4;
    background-color: rgba(201, 206, 212, var(--bg-opacity));
  }

  .desktop\:bg-grey7 {
    --bg-opacity: 1;
    background-color: #dfe2e6;
    background-color: rgba(223, 226, 230, var(--bg-opacity));
  }

  .desktop\:bg-grey8 {
    --bg-opacity: 1;
    background-color: #f0f2f5;
    background-color: rgba(240, 242, 245, var(--bg-opacity));
  }

  .desktop\:bg-grey9 {
    --bg-opacity: 1;
    background-color: #f5f7fa;
    background-color: rgba(245, 247, 250, var(--bg-opacity));
  }

  .desktop\:bg-white {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .desktop\:bg-black {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity));
  }

  .desktop\:bg-lightTeal {
    --bg-opacity: 1;
    background-color: #edf8f8;
    background-color: rgba(237, 248, 248, var(--bg-opacity));
  }

  .desktop\:bg-teal {
    --bg-opacity: 1;
    background-color: #46b4b4;
    background-color: rgba(70, 180, 180, var(--bg-opacity));
  }

  .desktop\:bg-darkTeal {
    --bg-opacity: 1;
    background-color: #46b4b4;
    background-color: rgba(70, 180, 180, var(--bg-opacity));
  }

  .desktop\:bg-tealBg {
    --bg-opacity: 1;
    background-color: #eff8f8;
    background-color: rgba(239, 248, 248, var(--bg-opacity));
  }

  .desktop\:bg-yellow {
    --bg-opacity: 1;
    background-color: #ff9f40;
    background-color: rgba(255, 159, 64, var(--bg-opacity));
  }

  .desktop\:bg-yellowBg {
    --bg-opacity: 1;
    background-color: #fff5eb;
    background-color: rgba(255, 245, 235, var(--bg-opacity));
  }

  .desktop\:bg-blue {
    --bg-opacity: 1;
    background-color: #569fe5;
    background-color: rgba(86, 159, 229, var(--bg-opacity));
  }

  .desktop\:bg-blueBg {
    --bg-opacity: 1;
    background-color: #ecf6fd;
    background-color: rgba(236, 246, 253, var(--bg-opacity));
  }

  .desktop\:bg-red {
    --bg-opacity: 1;
    background-color: #ed6c85;
    background-color: rgba(237, 108, 133, var(--bg-opacity));
  }

  .desktop\:bg-redBg {
    --bg-opacity: 1;
    background-color: #fdeff2;
    background-color: rgba(253, 239, 242, var(--bg-opacity));
  }

  .desktop\:bg-green {
    --bg-opacity: 1;
    background-color: #4bc0c0;
    background-color: rgba(75, 192, 192, var(--bg-opacity));
  }

  .desktop\:bg-error {
    --bg-opacity: 1;
    background-color: #FF0000;
    background-color: rgba(255, 0, 0, var(--bg-opacity));
  }

  .desktop\:bg-errorBg {
    --bg-opacity: 1;
    background-color: #ffeded;
    background-color: rgba(255, 237, 237, var(--bg-opacity));
  }

  .desktop\:bg-facebook {
    --bg-opacity: 1;
    background-color: #1877f2;
    background-color: rgba(24, 119, 242, var(--bg-opacity));
  }

  .desktop\:bg-instagram {
    --bg-opacity: 1;
    background-color: #e73558;
    background-color: rgba(231, 53, 88, var(--bg-opacity));
  }

  .desktop\:bg-instagram_basic {
    --bg-opacity: 1;
    background-color: #e73558;
    background-color: rgba(231, 53, 88, var(--bg-opacity));
  }

  .desktop\:bg-instagram_graph {
    --bg-opacity: 1;
    background-color: #e73558;
    background-color: rgba(231, 53, 88, var(--bg-opacity));
  }

  .desktop\:bg-linkedin {
    --bg-opacity: 1;
    background-color: #1b73b7;
    background-color: rgba(27, 115, 183, var(--bg-opacity));
  }

  .desktop\:bg-twitter {
    --bg-opacity: 1;
    background-color: #52a8f0;
    background-color: rgba(82, 168, 240, var(--bg-opacity));
  }

  .desktop\:bg-whatsAppGreen {
    --bg-opacity: 1;
    background-color: #25D366;
    background-color: rgba(37, 211, 102, var(--bg-opacity));
  }

  .desktop\:bg-socialiePink {
    --bg-opacity: 1;
    background-color: #FC2361;
    background-color: rgba(252, 35, 97, var(--bg-opacity));
  }

  .desktop\:bg-socialiePinkHover {
    --bg-opacity: 1;
    background-color: #FF3D71;
    background-color: rgba(255, 61, 113, var(--bg-opacity));
  }

  .desktop\:bg-socialieBlue {
    --bg-opacity: 1;
    background-color: #5CC7EA;
    background-color: rgba(92, 199, 234, var(--bg-opacity));
  }

  .desktop\:bg-badgeBlue {
    --bg-opacity: 1;
    background-color: #1c90ff;
    background-color: rgba(28, 144, 255, var(--bg-opacity));
  }

  .desktop\:bg-success {
    --bg-opacity: 1;
    background-color: #97cc04;
    background-color: rgba(151, 204, 4, var(--bg-opacity));
  }

  .desktop\:bg-bodyText {
    --bg-opacity: 1;
    background-color: #5a636e;
    background-color: rgba(90, 99, 110, var(--bg-opacity));
  }

  .desktop\:bg-bodyBg {
    --bg-opacity: 1;
    background-color: #f5f7fa;
    background-color: rgba(245, 247, 250, var(--bg-opacity));
  }

  .desktop\:bg-border {
    --bg-opacity: 1;
    background-color: #dfe2e6;
    background-color: rgba(223, 226, 230, var(--bg-opacity));
  }

  .desktop\:bg-boxShadow {
    background-color: rgba(0, 0, 0, 0.12);
  }

  .desktop\:bg-darkBorder {
    --bg-opacity: 1;
    background-color: #c9ced4;
    background-color: rgba(201, 206, 212, var(--bg-opacity));
  }

  .desktop\:bg-darkerBorder {
    --bg-opacity: 1;
    background-color: #5a636e;
    background-color: rgba(90, 99, 110, var(--bg-opacity));
  }

  .desktop\:bg-darkestGreyBg {
    --bg-opacity: 1;
    background-color: #5a636e;
    background-color: rgba(90, 99, 110, var(--bg-opacity));
  }

  .desktop\:bg-darkGreyBg {
    --bg-opacity: 1;
    background-color: #c9ced4;
    background-color: rgba(201, 206, 212, var(--bg-opacity));
  }

  .desktop\:bg-darkText {
    --bg-opacity: 1;
    background-color: #101820;
    background-color: rgba(16, 24, 32, var(--bg-opacity));
  }

  .desktop\:bg-draftBuilderBg {
    --bg-opacity: 1;
    background-color: #1c252f;
    background-color: rgba(28, 37, 47, var(--bg-opacity));
  }

  .desktop\:bg-semiBlack {
    background-color: rgba(0, 0, 0, 0.8);
  }

  .desktop\:bg-semiWhite {
    background-color: rgba(255, 255, 255, 0.8);
  }

  .desktop\:bg-focus {
    --bg-opacity: 1;
    background-color: #c9ced4;
    background-color: rgba(201, 206, 212, var(--bg-opacity));
  }

  .desktop\:bg-grey {
    --bg-opacity: 1;
    background-color: #5a636e;
    background-color: rgba(90, 99, 110, var(--bg-opacity));
  }

  .desktop\:bg-greyBg {
    --bg-opacity: 1;
    background-color: #f0f2f5;
    background-color: rgba(240, 242, 245, var(--bg-opacity));
  }

  .desktop\:bg-hoverBg {
    background-color: rgba(240,242,245,0.25);
  }

  .desktop\:bg-inverseBorder {
    --bg-opacity: 1;
    background-color: #343d46;
    background-color: rgba(52, 61, 70, var(--bg-opacity));
  }

  .desktop\:bg-messageBg {
    --bg-opacity: 1;
    background-color: #36a2eb;
    background-color: rgba(54, 162, 235, var(--bg-opacity));
  }

  .desktop\:bg-lightBorder {
    --bg-opacity: 1;
    background-color: #f0f2f5;
    background-color: rgba(240, 242, 245, var(--bg-opacity));
  }

  .desktop\:bg-lighterText {
    --bg-opacity: 1;
    background-color: #c9ced4;
    background-color: rgba(201, 206, 212, var(--bg-opacity));
  }

  .desktop\:bg-lightIcon {
    --bg-opacity: 1;
    background-color: #c9ced4;
    background-color: rgba(201, 206, 212, var(--bg-opacity));
  }

  .desktop\:bg-lightText {
    --bg-opacity: 1;
    background-color: #9198a1;
    background-color: rgba(145, 152, 161, var(--bg-opacity));
  }

  .desktop\:bg-link {
    --bg-opacity: 1;
    background-color: #569fe5;
    background-color: rgba(86, 159, 229, var(--bg-opacity));
  }

  .desktop\:bg-default {
    --bg-opacity: 1;
    background-color: #5a636e;
    background-color: rgba(90, 99, 110, var(--bg-opacity));
  }

  .desktop\:hover\:bg-transparent:hover {
    background-color: transparent;
  }

  .desktop\:hover\:bg-grey1:hover {
    --bg-opacity: 1;
    background-color: #101820;
    background-color: rgba(16, 24, 32, var(--bg-opacity));
  }

  .desktop\:hover\:bg-grey2:hover {
    --bg-opacity: 1;
    background-color: #1c252f;
    background-color: rgba(28, 37, 47, var(--bg-opacity));
  }

  .desktop\:hover\:bg-grey3:hover {
    --bg-opacity: 1;
    background-color: #343d46;
    background-color: rgba(52, 61, 70, var(--bg-opacity));
  }

  .desktop\:hover\:bg-grey4:hover {
    --bg-opacity: 1;
    background-color: #5a636e;
    background-color: rgba(90, 99, 110, var(--bg-opacity));
  }

  .desktop\:hover\:bg-grey5:hover {
    --bg-opacity: 1;
    background-color: #9198a1;
    background-color: rgba(145, 152, 161, var(--bg-opacity));
  }

  .desktop\:hover\:bg-grey6:hover {
    --bg-opacity: 1;
    background-color: #c9ced4;
    background-color: rgba(201, 206, 212, var(--bg-opacity));
  }

  .desktop\:hover\:bg-grey7:hover {
    --bg-opacity: 1;
    background-color: #dfe2e6;
    background-color: rgba(223, 226, 230, var(--bg-opacity));
  }

  .desktop\:hover\:bg-grey8:hover {
    --bg-opacity: 1;
    background-color: #f0f2f5;
    background-color: rgba(240, 242, 245, var(--bg-opacity));
  }

  .desktop\:hover\:bg-grey9:hover {
    --bg-opacity: 1;
    background-color: #f5f7fa;
    background-color: rgba(245, 247, 250, var(--bg-opacity));
  }

  .desktop\:hover\:bg-white:hover {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .desktop\:hover\:bg-black:hover {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity));
  }

  .desktop\:hover\:bg-lightTeal:hover {
    --bg-opacity: 1;
    background-color: #edf8f8;
    background-color: rgba(237, 248, 248, var(--bg-opacity));
  }

  .desktop\:hover\:bg-teal:hover {
    --bg-opacity: 1;
    background-color: #46b4b4;
    background-color: rgba(70, 180, 180, var(--bg-opacity));
  }

  .desktop\:hover\:bg-darkTeal:hover {
    --bg-opacity: 1;
    background-color: #46b4b4;
    background-color: rgba(70, 180, 180, var(--bg-opacity));
  }

  .desktop\:hover\:bg-tealBg:hover {
    --bg-opacity: 1;
    background-color: #eff8f8;
    background-color: rgba(239, 248, 248, var(--bg-opacity));
  }

  .desktop\:hover\:bg-yellow:hover {
    --bg-opacity: 1;
    background-color: #ff9f40;
    background-color: rgba(255, 159, 64, var(--bg-opacity));
  }

  .desktop\:hover\:bg-yellowBg:hover {
    --bg-opacity: 1;
    background-color: #fff5eb;
    background-color: rgba(255, 245, 235, var(--bg-opacity));
  }

  .desktop\:hover\:bg-blue:hover {
    --bg-opacity: 1;
    background-color: #569fe5;
    background-color: rgba(86, 159, 229, var(--bg-opacity));
  }

  .desktop\:hover\:bg-blueBg:hover {
    --bg-opacity: 1;
    background-color: #ecf6fd;
    background-color: rgba(236, 246, 253, var(--bg-opacity));
  }

  .desktop\:hover\:bg-red:hover {
    --bg-opacity: 1;
    background-color: #ed6c85;
    background-color: rgba(237, 108, 133, var(--bg-opacity));
  }

  .desktop\:hover\:bg-redBg:hover {
    --bg-opacity: 1;
    background-color: #fdeff2;
    background-color: rgba(253, 239, 242, var(--bg-opacity));
  }

  .desktop\:hover\:bg-green:hover {
    --bg-opacity: 1;
    background-color: #4bc0c0;
    background-color: rgba(75, 192, 192, var(--bg-opacity));
  }

  .desktop\:hover\:bg-error:hover {
    --bg-opacity: 1;
    background-color: #FF0000;
    background-color: rgba(255, 0, 0, var(--bg-opacity));
  }

  .desktop\:hover\:bg-errorBg:hover {
    --bg-opacity: 1;
    background-color: #ffeded;
    background-color: rgba(255, 237, 237, var(--bg-opacity));
  }

  .desktop\:hover\:bg-facebook:hover {
    --bg-opacity: 1;
    background-color: #1877f2;
    background-color: rgba(24, 119, 242, var(--bg-opacity));
  }

  .desktop\:hover\:bg-instagram:hover {
    --bg-opacity: 1;
    background-color: #e73558;
    background-color: rgba(231, 53, 88, var(--bg-opacity));
  }

  .desktop\:hover\:bg-instagram_basic:hover {
    --bg-opacity: 1;
    background-color: #e73558;
    background-color: rgba(231, 53, 88, var(--bg-opacity));
  }

  .desktop\:hover\:bg-instagram_graph:hover {
    --bg-opacity: 1;
    background-color: #e73558;
    background-color: rgba(231, 53, 88, var(--bg-opacity));
  }

  .desktop\:hover\:bg-linkedin:hover {
    --bg-opacity: 1;
    background-color: #1b73b7;
    background-color: rgba(27, 115, 183, var(--bg-opacity));
  }

  .desktop\:hover\:bg-twitter:hover {
    --bg-opacity: 1;
    background-color: #52a8f0;
    background-color: rgba(82, 168, 240, var(--bg-opacity));
  }

  .desktop\:hover\:bg-whatsAppGreen:hover {
    --bg-opacity: 1;
    background-color: #25D366;
    background-color: rgba(37, 211, 102, var(--bg-opacity));
  }

  .desktop\:hover\:bg-socialiePink:hover {
    --bg-opacity: 1;
    background-color: #FC2361;
    background-color: rgba(252, 35, 97, var(--bg-opacity));
  }

  .desktop\:hover\:bg-socialiePinkHover:hover {
    --bg-opacity: 1;
    background-color: #FF3D71;
    background-color: rgba(255, 61, 113, var(--bg-opacity));
  }

  .desktop\:hover\:bg-socialieBlue:hover {
    --bg-opacity: 1;
    background-color: #5CC7EA;
    background-color: rgba(92, 199, 234, var(--bg-opacity));
  }

  .desktop\:hover\:bg-badgeBlue:hover {
    --bg-opacity: 1;
    background-color: #1c90ff;
    background-color: rgba(28, 144, 255, var(--bg-opacity));
  }

  .desktop\:hover\:bg-success:hover {
    --bg-opacity: 1;
    background-color: #97cc04;
    background-color: rgba(151, 204, 4, var(--bg-opacity));
  }

  .desktop\:hover\:bg-bodyText:hover {
    --bg-opacity: 1;
    background-color: #5a636e;
    background-color: rgba(90, 99, 110, var(--bg-opacity));
  }

  .desktop\:hover\:bg-bodyBg:hover {
    --bg-opacity: 1;
    background-color: #f5f7fa;
    background-color: rgba(245, 247, 250, var(--bg-opacity));
  }

  .desktop\:hover\:bg-border:hover {
    --bg-opacity: 1;
    background-color: #dfe2e6;
    background-color: rgba(223, 226, 230, var(--bg-opacity));
  }

  .desktop\:hover\:bg-boxShadow:hover {
    background-color: rgba(0, 0, 0, 0.12);
  }

  .desktop\:hover\:bg-darkBorder:hover {
    --bg-opacity: 1;
    background-color: #c9ced4;
    background-color: rgba(201, 206, 212, var(--bg-opacity));
  }

  .desktop\:hover\:bg-darkerBorder:hover {
    --bg-opacity: 1;
    background-color: #5a636e;
    background-color: rgba(90, 99, 110, var(--bg-opacity));
  }

  .desktop\:hover\:bg-darkestGreyBg:hover {
    --bg-opacity: 1;
    background-color: #5a636e;
    background-color: rgba(90, 99, 110, var(--bg-opacity));
  }

  .desktop\:hover\:bg-darkGreyBg:hover {
    --bg-opacity: 1;
    background-color: #c9ced4;
    background-color: rgba(201, 206, 212, var(--bg-opacity));
  }

  .desktop\:hover\:bg-darkText:hover {
    --bg-opacity: 1;
    background-color: #101820;
    background-color: rgba(16, 24, 32, var(--bg-opacity));
  }

  .desktop\:hover\:bg-draftBuilderBg:hover {
    --bg-opacity: 1;
    background-color: #1c252f;
    background-color: rgba(28, 37, 47, var(--bg-opacity));
  }

  .desktop\:hover\:bg-semiBlack:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }

  .desktop\:hover\:bg-semiWhite:hover {
    background-color: rgba(255, 255, 255, 0.8);
  }

  .desktop\:hover\:bg-focus:hover {
    --bg-opacity: 1;
    background-color: #c9ced4;
    background-color: rgba(201, 206, 212, var(--bg-opacity));
  }

  .desktop\:hover\:bg-grey:hover {
    --bg-opacity: 1;
    background-color: #5a636e;
    background-color: rgba(90, 99, 110, var(--bg-opacity));
  }

  .desktop\:hover\:bg-greyBg:hover {
    --bg-opacity: 1;
    background-color: #f0f2f5;
    background-color: rgba(240, 242, 245, var(--bg-opacity));
  }

  .desktop\:hover\:bg-hoverBg:hover {
    background-color: rgba(240,242,245,0.25);
  }

  .desktop\:hover\:bg-inverseBorder:hover {
    --bg-opacity: 1;
    background-color: #343d46;
    background-color: rgba(52, 61, 70, var(--bg-opacity));
  }

  .desktop\:hover\:bg-messageBg:hover {
    --bg-opacity: 1;
    background-color: #36a2eb;
    background-color: rgba(54, 162, 235, var(--bg-opacity));
  }

  .desktop\:hover\:bg-lightBorder:hover {
    --bg-opacity: 1;
    background-color: #f0f2f5;
    background-color: rgba(240, 242, 245, var(--bg-opacity));
  }

  .desktop\:hover\:bg-lighterText:hover {
    --bg-opacity: 1;
    background-color: #c9ced4;
    background-color: rgba(201, 206, 212, var(--bg-opacity));
  }

  .desktop\:hover\:bg-lightIcon:hover {
    --bg-opacity: 1;
    background-color: #c9ced4;
    background-color: rgba(201, 206, 212, var(--bg-opacity));
  }

  .desktop\:hover\:bg-lightText:hover {
    --bg-opacity: 1;
    background-color: #9198a1;
    background-color: rgba(145, 152, 161, var(--bg-opacity));
  }

  .desktop\:hover\:bg-link:hover {
    --bg-opacity: 1;
    background-color: #569fe5;
    background-color: rgba(86, 159, 229, var(--bg-opacity));
  }

  .desktop\:hover\:bg-default:hover {
    --bg-opacity: 1;
    background-color: #5a636e;
    background-color: rgba(90, 99, 110, var(--bg-opacity));
  }

  .desktop\:focus\:bg-transparent:focus {
    background-color: transparent;
  }

  .desktop\:focus\:bg-grey1:focus {
    --bg-opacity: 1;
    background-color: #101820;
    background-color: rgba(16, 24, 32, var(--bg-opacity));
  }

  .desktop\:focus\:bg-grey2:focus {
    --bg-opacity: 1;
    background-color: #1c252f;
    background-color: rgba(28, 37, 47, var(--bg-opacity));
  }

  .desktop\:focus\:bg-grey3:focus {
    --bg-opacity: 1;
    background-color: #343d46;
    background-color: rgba(52, 61, 70, var(--bg-opacity));
  }

  .desktop\:focus\:bg-grey4:focus {
    --bg-opacity: 1;
    background-color: #5a636e;
    background-color: rgba(90, 99, 110, var(--bg-opacity));
  }

  .desktop\:focus\:bg-grey5:focus {
    --bg-opacity: 1;
    background-color: #9198a1;
    background-color: rgba(145, 152, 161, var(--bg-opacity));
  }

  .desktop\:focus\:bg-grey6:focus {
    --bg-opacity: 1;
    background-color: #c9ced4;
    background-color: rgba(201, 206, 212, var(--bg-opacity));
  }

  .desktop\:focus\:bg-grey7:focus {
    --bg-opacity: 1;
    background-color: #dfe2e6;
    background-color: rgba(223, 226, 230, var(--bg-opacity));
  }

  .desktop\:focus\:bg-grey8:focus {
    --bg-opacity: 1;
    background-color: #f0f2f5;
    background-color: rgba(240, 242, 245, var(--bg-opacity));
  }

  .desktop\:focus\:bg-grey9:focus {
    --bg-opacity: 1;
    background-color: #f5f7fa;
    background-color: rgba(245, 247, 250, var(--bg-opacity));
  }

  .desktop\:focus\:bg-white:focus {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .desktop\:focus\:bg-black:focus {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity));
  }

  .desktop\:focus\:bg-lightTeal:focus {
    --bg-opacity: 1;
    background-color: #edf8f8;
    background-color: rgba(237, 248, 248, var(--bg-opacity));
  }

  .desktop\:focus\:bg-teal:focus {
    --bg-opacity: 1;
    background-color: #46b4b4;
    background-color: rgba(70, 180, 180, var(--bg-opacity));
  }

  .desktop\:focus\:bg-darkTeal:focus {
    --bg-opacity: 1;
    background-color: #46b4b4;
    background-color: rgba(70, 180, 180, var(--bg-opacity));
  }

  .desktop\:focus\:bg-tealBg:focus {
    --bg-opacity: 1;
    background-color: #eff8f8;
    background-color: rgba(239, 248, 248, var(--bg-opacity));
  }

  .desktop\:focus\:bg-yellow:focus {
    --bg-opacity: 1;
    background-color: #ff9f40;
    background-color: rgba(255, 159, 64, var(--bg-opacity));
  }

  .desktop\:focus\:bg-yellowBg:focus {
    --bg-opacity: 1;
    background-color: #fff5eb;
    background-color: rgba(255, 245, 235, var(--bg-opacity));
  }

  .desktop\:focus\:bg-blue:focus {
    --bg-opacity: 1;
    background-color: #569fe5;
    background-color: rgba(86, 159, 229, var(--bg-opacity));
  }

  .desktop\:focus\:bg-blueBg:focus {
    --bg-opacity: 1;
    background-color: #ecf6fd;
    background-color: rgba(236, 246, 253, var(--bg-opacity));
  }

  .desktop\:focus\:bg-red:focus {
    --bg-opacity: 1;
    background-color: #ed6c85;
    background-color: rgba(237, 108, 133, var(--bg-opacity));
  }

  .desktop\:focus\:bg-redBg:focus {
    --bg-opacity: 1;
    background-color: #fdeff2;
    background-color: rgba(253, 239, 242, var(--bg-opacity));
  }

  .desktop\:focus\:bg-green:focus {
    --bg-opacity: 1;
    background-color: #4bc0c0;
    background-color: rgba(75, 192, 192, var(--bg-opacity));
  }

  .desktop\:focus\:bg-error:focus {
    --bg-opacity: 1;
    background-color: #FF0000;
    background-color: rgba(255, 0, 0, var(--bg-opacity));
  }

  .desktop\:focus\:bg-errorBg:focus {
    --bg-opacity: 1;
    background-color: #ffeded;
    background-color: rgba(255, 237, 237, var(--bg-opacity));
  }

  .desktop\:focus\:bg-facebook:focus {
    --bg-opacity: 1;
    background-color: #1877f2;
    background-color: rgba(24, 119, 242, var(--bg-opacity));
  }

  .desktop\:focus\:bg-instagram:focus {
    --bg-opacity: 1;
    background-color: #e73558;
    background-color: rgba(231, 53, 88, var(--bg-opacity));
  }

  .desktop\:focus\:bg-instagram_basic:focus {
    --bg-opacity: 1;
    background-color: #e73558;
    background-color: rgba(231, 53, 88, var(--bg-opacity));
  }

  .desktop\:focus\:bg-instagram_graph:focus {
    --bg-opacity: 1;
    background-color: #e73558;
    background-color: rgba(231, 53, 88, var(--bg-opacity));
  }

  .desktop\:focus\:bg-linkedin:focus {
    --bg-opacity: 1;
    background-color: #1b73b7;
    background-color: rgba(27, 115, 183, var(--bg-opacity));
  }

  .desktop\:focus\:bg-twitter:focus {
    --bg-opacity: 1;
    background-color: #52a8f0;
    background-color: rgba(82, 168, 240, var(--bg-opacity));
  }

  .desktop\:focus\:bg-whatsAppGreen:focus {
    --bg-opacity: 1;
    background-color: #25D366;
    background-color: rgba(37, 211, 102, var(--bg-opacity));
  }

  .desktop\:focus\:bg-socialiePink:focus {
    --bg-opacity: 1;
    background-color: #FC2361;
    background-color: rgba(252, 35, 97, var(--bg-opacity));
  }

  .desktop\:focus\:bg-socialiePinkHover:focus {
    --bg-opacity: 1;
    background-color: #FF3D71;
    background-color: rgba(255, 61, 113, var(--bg-opacity));
  }

  .desktop\:focus\:bg-socialieBlue:focus {
    --bg-opacity: 1;
    background-color: #5CC7EA;
    background-color: rgba(92, 199, 234, var(--bg-opacity));
  }

  .desktop\:focus\:bg-badgeBlue:focus {
    --bg-opacity: 1;
    background-color: #1c90ff;
    background-color: rgba(28, 144, 255, var(--bg-opacity));
  }

  .desktop\:focus\:bg-success:focus {
    --bg-opacity: 1;
    background-color: #97cc04;
    background-color: rgba(151, 204, 4, var(--bg-opacity));
  }

  .desktop\:focus\:bg-bodyText:focus {
    --bg-opacity: 1;
    background-color: #5a636e;
    background-color: rgba(90, 99, 110, var(--bg-opacity));
  }

  .desktop\:focus\:bg-bodyBg:focus {
    --bg-opacity: 1;
    background-color: #f5f7fa;
    background-color: rgba(245, 247, 250, var(--bg-opacity));
  }

  .desktop\:focus\:bg-border:focus {
    --bg-opacity: 1;
    background-color: #dfe2e6;
    background-color: rgba(223, 226, 230, var(--bg-opacity));
  }

  .desktop\:focus\:bg-boxShadow:focus {
    background-color: rgba(0, 0, 0, 0.12);
  }

  .desktop\:focus\:bg-darkBorder:focus {
    --bg-opacity: 1;
    background-color: #c9ced4;
    background-color: rgba(201, 206, 212, var(--bg-opacity));
  }

  .desktop\:focus\:bg-darkerBorder:focus {
    --bg-opacity: 1;
    background-color: #5a636e;
    background-color: rgba(90, 99, 110, var(--bg-opacity));
  }

  .desktop\:focus\:bg-darkestGreyBg:focus {
    --bg-opacity: 1;
    background-color: #5a636e;
    background-color: rgba(90, 99, 110, var(--bg-opacity));
  }

  .desktop\:focus\:bg-darkGreyBg:focus {
    --bg-opacity: 1;
    background-color: #c9ced4;
    background-color: rgba(201, 206, 212, var(--bg-opacity));
  }

  .desktop\:focus\:bg-darkText:focus {
    --bg-opacity: 1;
    background-color: #101820;
    background-color: rgba(16, 24, 32, var(--bg-opacity));
  }

  .desktop\:focus\:bg-draftBuilderBg:focus {
    --bg-opacity: 1;
    background-color: #1c252f;
    background-color: rgba(28, 37, 47, var(--bg-opacity));
  }

  .desktop\:focus\:bg-semiBlack:focus {
    background-color: rgba(0, 0, 0, 0.8);
  }

  .desktop\:focus\:bg-semiWhite:focus {
    background-color: rgba(255, 255, 255, 0.8);
  }

  .desktop\:focus\:bg-focus:focus {
    --bg-opacity: 1;
    background-color: #c9ced4;
    background-color: rgba(201, 206, 212, var(--bg-opacity));
  }

  .desktop\:focus\:bg-grey:focus {
    --bg-opacity: 1;
    background-color: #5a636e;
    background-color: rgba(90, 99, 110, var(--bg-opacity));
  }

  .desktop\:focus\:bg-greyBg:focus {
    --bg-opacity: 1;
    background-color: #f0f2f5;
    background-color: rgba(240, 242, 245, var(--bg-opacity));
  }

  .desktop\:focus\:bg-hoverBg:focus {
    background-color: rgba(240,242,245,0.25);
  }

  .desktop\:focus\:bg-inverseBorder:focus {
    --bg-opacity: 1;
    background-color: #343d46;
    background-color: rgba(52, 61, 70, var(--bg-opacity));
  }

  .desktop\:focus\:bg-messageBg:focus {
    --bg-opacity: 1;
    background-color: #36a2eb;
    background-color: rgba(54, 162, 235, var(--bg-opacity));
  }

  .desktop\:focus\:bg-lightBorder:focus {
    --bg-opacity: 1;
    background-color: #f0f2f5;
    background-color: rgba(240, 242, 245, var(--bg-opacity));
  }

  .desktop\:focus\:bg-lighterText:focus {
    --bg-opacity: 1;
    background-color: #c9ced4;
    background-color: rgba(201, 206, 212, var(--bg-opacity));
  }

  .desktop\:focus\:bg-lightIcon:focus {
    --bg-opacity: 1;
    background-color: #c9ced4;
    background-color: rgba(201, 206, 212, var(--bg-opacity));
  }

  .desktop\:focus\:bg-lightText:focus {
    --bg-opacity: 1;
    background-color: #9198a1;
    background-color: rgba(145, 152, 161, var(--bg-opacity));
  }

  .desktop\:focus\:bg-link:focus {
    --bg-opacity: 1;
    background-color: #569fe5;
    background-color: rgba(86, 159, 229, var(--bg-opacity));
  }

  .desktop\:focus\:bg-default:focus {
    --bg-opacity: 1;
    background-color: #5a636e;
    background-color: rgba(90, 99, 110, var(--bg-opacity));
  }

  .desktop\:bg-opacity-0 {
    --bg-opacity: 0;
  }

  .desktop\:bg-opacity-10 {
    --bg-opacity: 0.1;
  }

  .desktop\:bg-opacity-25 {
    --bg-opacity: 0.25;
  }

  .desktop\:bg-opacity-50 {
    --bg-opacity: 0.5;
  }

  .desktop\:bg-opacity-75 {
    --bg-opacity: 0.75;
  }

  .desktop\:bg-opacity-92 {
    --bg-opacity: 0.92px;
  }

  .desktop\:bg-opacity-100 {
    --bg-opacity: 1;
  }

  .desktop\:hover\:bg-opacity-0:hover {
    --bg-opacity: 0;
  }

  .desktop\:hover\:bg-opacity-10:hover {
    --bg-opacity: 0.1;
  }

  .desktop\:hover\:bg-opacity-25:hover {
    --bg-opacity: 0.25;
  }

  .desktop\:hover\:bg-opacity-50:hover {
    --bg-opacity: 0.5;
  }

  .desktop\:hover\:bg-opacity-75:hover {
    --bg-opacity: 0.75;
  }

  .desktop\:hover\:bg-opacity-92:hover {
    --bg-opacity: 0.92px;
  }

  .desktop\:hover\:bg-opacity-100:hover {
    --bg-opacity: 1;
  }

  .desktop\:focus\:bg-opacity-0:focus {
    --bg-opacity: 0;
  }

  .desktop\:focus\:bg-opacity-10:focus {
    --bg-opacity: 0.1;
  }

  .desktop\:focus\:bg-opacity-25:focus {
    --bg-opacity: 0.25;
  }

  .desktop\:focus\:bg-opacity-50:focus {
    --bg-opacity: 0.5;
  }

  .desktop\:focus\:bg-opacity-75:focus {
    --bg-opacity: 0.75;
  }

  .desktop\:focus\:bg-opacity-92:focus {
    --bg-opacity: 0.92px;
  }

  .desktop\:focus\:bg-opacity-100:focus {
    --bg-opacity: 1;
  }

  .desktop\:bg-bottom {
    background-position: bottom;
  }

  .desktop\:bg-center {
    background-position: center;
  }

  .desktop\:bg-left {
    background-position: left;
  }

  .desktop\:bg-left-bottom {
    background-position: left bottom;
  }

  .desktop\:bg-left-top {
    background-position: left top;
  }

  .desktop\:bg-right {
    background-position: right;
  }

  .desktop\:bg-right-bottom {
    background-position: right bottom;
  }

  .desktop\:bg-right-top {
    background-position: right top;
  }

  .desktop\:bg-top {
    background-position: top;
  }

  .desktop\:bg-repeat {
    background-repeat: repeat;
  }

  .desktop\:bg-no-repeat {
    background-repeat: no-repeat;
  }

  .desktop\:bg-repeat-x {
    background-repeat: repeat-x;
  }

  .desktop\:bg-repeat-y {
    background-repeat: repeat-y;
  }

  .desktop\:bg-repeat-round {
    background-repeat: round;
  }

  .desktop\:bg-repeat-space {
    background-repeat: space;
  }

  .desktop\:bg-auto {
    background-size: auto;
  }

  .desktop\:bg-cover {
    background-size: cover;
  }

  .desktop\:bg-contain {
    background-size: contain;
  }
}

@media (min-width: 1200px) {

  .max\:bg-fixed {
    background-attachment: fixed;
  }

  .max\:bg-local {
    background-attachment: local;
  }

  .max\:bg-scroll {
    background-attachment: scroll;
  }

  .max\:bg-transparent {
    background-color: transparent;
  }

  .max\:bg-grey1 {
    --bg-opacity: 1;
    background-color: #101820;
    background-color: rgba(16, 24, 32, var(--bg-opacity));
  }

  .max\:bg-grey2 {
    --bg-opacity: 1;
    background-color: #1c252f;
    background-color: rgba(28, 37, 47, var(--bg-opacity));
  }

  .max\:bg-grey3 {
    --bg-opacity: 1;
    background-color: #343d46;
    background-color: rgba(52, 61, 70, var(--bg-opacity));
  }

  .max\:bg-grey4 {
    --bg-opacity: 1;
    background-color: #5a636e;
    background-color: rgba(90, 99, 110, var(--bg-opacity));
  }

  .max\:bg-grey5 {
    --bg-opacity: 1;
    background-color: #9198a1;
    background-color: rgba(145, 152, 161, var(--bg-opacity));
  }

  .max\:bg-grey6 {
    --bg-opacity: 1;
    background-color: #c9ced4;
    background-color: rgba(201, 206, 212, var(--bg-opacity));
  }

  .max\:bg-grey7 {
    --bg-opacity: 1;
    background-color: #dfe2e6;
    background-color: rgba(223, 226, 230, var(--bg-opacity));
  }

  .max\:bg-grey8 {
    --bg-opacity: 1;
    background-color: #f0f2f5;
    background-color: rgba(240, 242, 245, var(--bg-opacity));
  }

  .max\:bg-grey9 {
    --bg-opacity: 1;
    background-color: #f5f7fa;
    background-color: rgba(245, 247, 250, var(--bg-opacity));
  }

  .max\:bg-white {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .max\:bg-black {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity));
  }

  .max\:bg-lightTeal {
    --bg-opacity: 1;
    background-color: #edf8f8;
    background-color: rgba(237, 248, 248, var(--bg-opacity));
  }

  .max\:bg-teal {
    --bg-opacity: 1;
    background-color: #46b4b4;
    background-color: rgba(70, 180, 180, var(--bg-opacity));
  }

  .max\:bg-darkTeal {
    --bg-opacity: 1;
    background-color: #46b4b4;
    background-color: rgba(70, 180, 180, var(--bg-opacity));
  }

  .max\:bg-tealBg {
    --bg-opacity: 1;
    background-color: #eff8f8;
    background-color: rgba(239, 248, 248, var(--bg-opacity));
  }

  .max\:bg-yellow {
    --bg-opacity: 1;
    background-color: #ff9f40;
    background-color: rgba(255, 159, 64, var(--bg-opacity));
  }

  .max\:bg-yellowBg {
    --bg-opacity: 1;
    background-color: #fff5eb;
    background-color: rgba(255, 245, 235, var(--bg-opacity));
  }

  .max\:bg-blue {
    --bg-opacity: 1;
    background-color: #569fe5;
    background-color: rgba(86, 159, 229, var(--bg-opacity));
  }

  .max\:bg-blueBg {
    --bg-opacity: 1;
    background-color: #ecf6fd;
    background-color: rgba(236, 246, 253, var(--bg-opacity));
  }

  .max\:bg-red {
    --bg-opacity: 1;
    background-color: #ed6c85;
    background-color: rgba(237, 108, 133, var(--bg-opacity));
  }

  .max\:bg-redBg {
    --bg-opacity: 1;
    background-color: #fdeff2;
    background-color: rgba(253, 239, 242, var(--bg-opacity));
  }

  .max\:bg-green {
    --bg-opacity: 1;
    background-color: #4bc0c0;
    background-color: rgba(75, 192, 192, var(--bg-opacity));
  }

  .max\:bg-error {
    --bg-opacity: 1;
    background-color: #FF0000;
    background-color: rgba(255, 0, 0, var(--bg-opacity));
  }

  .max\:bg-errorBg {
    --bg-opacity: 1;
    background-color: #ffeded;
    background-color: rgba(255, 237, 237, var(--bg-opacity));
  }

  .max\:bg-facebook {
    --bg-opacity: 1;
    background-color: #1877f2;
    background-color: rgba(24, 119, 242, var(--bg-opacity));
  }

  .max\:bg-instagram {
    --bg-opacity: 1;
    background-color: #e73558;
    background-color: rgba(231, 53, 88, var(--bg-opacity));
  }

  .max\:bg-instagram_basic {
    --bg-opacity: 1;
    background-color: #e73558;
    background-color: rgba(231, 53, 88, var(--bg-opacity));
  }

  .max\:bg-instagram_graph {
    --bg-opacity: 1;
    background-color: #e73558;
    background-color: rgba(231, 53, 88, var(--bg-opacity));
  }

  .max\:bg-linkedin {
    --bg-opacity: 1;
    background-color: #1b73b7;
    background-color: rgba(27, 115, 183, var(--bg-opacity));
  }

  .max\:bg-twitter {
    --bg-opacity: 1;
    background-color: #52a8f0;
    background-color: rgba(82, 168, 240, var(--bg-opacity));
  }

  .max\:bg-whatsAppGreen {
    --bg-opacity: 1;
    background-color: #25D366;
    background-color: rgba(37, 211, 102, var(--bg-opacity));
  }

  .max\:bg-socialiePink {
    --bg-opacity: 1;
    background-color: #FC2361;
    background-color: rgba(252, 35, 97, var(--bg-opacity));
  }

  .max\:bg-socialiePinkHover {
    --bg-opacity: 1;
    background-color: #FF3D71;
    background-color: rgba(255, 61, 113, var(--bg-opacity));
  }

  .max\:bg-socialieBlue {
    --bg-opacity: 1;
    background-color: #5CC7EA;
    background-color: rgba(92, 199, 234, var(--bg-opacity));
  }

  .max\:bg-badgeBlue {
    --bg-opacity: 1;
    background-color: #1c90ff;
    background-color: rgba(28, 144, 255, var(--bg-opacity));
  }

  .max\:bg-success {
    --bg-opacity: 1;
    background-color: #97cc04;
    background-color: rgba(151, 204, 4, var(--bg-opacity));
  }

  .max\:bg-bodyText {
    --bg-opacity: 1;
    background-color: #5a636e;
    background-color: rgba(90, 99, 110, var(--bg-opacity));
  }

  .max\:bg-bodyBg {
    --bg-opacity: 1;
    background-color: #f5f7fa;
    background-color: rgba(245, 247, 250, var(--bg-opacity));
  }

  .max\:bg-border {
    --bg-opacity: 1;
    background-color: #dfe2e6;
    background-color: rgba(223, 226, 230, var(--bg-opacity));
  }

  .max\:bg-boxShadow {
    background-color: rgba(0, 0, 0, 0.12);
  }

  .max\:bg-darkBorder {
    --bg-opacity: 1;
    background-color: #c9ced4;
    background-color: rgba(201, 206, 212, var(--bg-opacity));
  }

  .max\:bg-darkerBorder {
    --bg-opacity: 1;
    background-color: #5a636e;
    background-color: rgba(90, 99, 110, var(--bg-opacity));
  }

  .max\:bg-darkestGreyBg {
    --bg-opacity: 1;
    background-color: #5a636e;
    background-color: rgba(90, 99, 110, var(--bg-opacity));
  }

  .max\:bg-darkGreyBg {
    --bg-opacity: 1;
    background-color: #c9ced4;
    background-color: rgba(201, 206, 212, var(--bg-opacity));
  }

  .max\:bg-darkText {
    --bg-opacity: 1;
    background-color: #101820;
    background-color: rgba(16, 24, 32, var(--bg-opacity));
  }

  .max\:bg-draftBuilderBg {
    --bg-opacity: 1;
    background-color: #1c252f;
    background-color: rgba(28, 37, 47, var(--bg-opacity));
  }

  .max\:bg-semiBlack {
    background-color: rgba(0, 0, 0, 0.8);
  }

  .max\:bg-semiWhite {
    background-color: rgba(255, 255, 255, 0.8);
  }

  .max\:bg-focus {
    --bg-opacity: 1;
    background-color: #c9ced4;
    background-color: rgba(201, 206, 212, var(--bg-opacity));
  }

  .max\:bg-grey {
    --bg-opacity: 1;
    background-color: #5a636e;
    background-color: rgba(90, 99, 110, var(--bg-opacity));
  }

  .max\:bg-greyBg {
    --bg-opacity: 1;
    background-color: #f0f2f5;
    background-color: rgba(240, 242, 245, var(--bg-opacity));
  }

  .max\:bg-hoverBg {
    background-color: rgba(240,242,245,0.25);
  }

  .max\:bg-inverseBorder {
    --bg-opacity: 1;
    background-color: #343d46;
    background-color: rgba(52, 61, 70, var(--bg-opacity));
  }

  .max\:bg-messageBg {
    --bg-opacity: 1;
    background-color: #36a2eb;
    background-color: rgba(54, 162, 235, var(--bg-opacity));
  }

  .max\:bg-lightBorder {
    --bg-opacity: 1;
    background-color: #f0f2f5;
    background-color: rgba(240, 242, 245, var(--bg-opacity));
  }

  .max\:bg-lighterText {
    --bg-opacity: 1;
    background-color: #c9ced4;
    background-color: rgba(201, 206, 212, var(--bg-opacity));
  }

  .max\:bg-lightIcon {
    --bg-opacity: 1;
    background-color: #c9ced4;
    background-color: rgba(201, 206, 212, var(--bg-opacity));
  }

  .max\:bg-lightText {
    --bg-opacity: 1;
    background-color: #9198a1;
    background-color: rgba(145, 152, 161, var(--bg-opacity));
  }

  .max\:bg-link {
    --bg-opacity: 1;
    background-color: #569fe5;
    background-color: rgba(86, 159, 229, var(--bg-opacity));
  }

  .max\:bg-default {
    --bg-opacity: 1;
    background-color: #5a636e;
    background-color: rgba(90, 99, 110, var(--bg-opacity));
  }

  .max\:hover\:bg-transparent:hover {
    background-color: transparent;
  }

  .max\:hover\:bg-grey1:hover {
    --bg-opacity: 1;
    background-color: #101820;
    background-color: rgba(16, 24, 32, var(--bg-opacity));
  }

  .max\:hover\:bg-grey2:hover {
    --bg-opacity: 1;
    background-color: #1c252f;
    background-color: rgba(28, 37, 47, var(--bg-opacity));
  }

  .max\:hover\:bg-grey3:hover {
    --bg-opacity: 1;
    background-color: #343d46;
    background-color: rgba(52, 61, 70, var(--bg-opacity));
  }

  .max\:hover\:bg-grey4:hover {
    --bg-opacity: 1;
    background-color: #5a636e;
    background-color: rgba(90, 99, 110, var(--bg-opacity));
  }

  .max\:hover\:bg-grey5:hover {
    --bg-opacity: 1;
    background-color: #9198a1;
    background-color: rgba(145, 152, 161, var(--bg-opacity));
  }

  .max\:hover\:bg-grey6:hover {
    --bg-opacity: 1;
    background-color: #c9ced4;
    background-color: rgba(201, 206, 212, var(--bg-opacity));
  }

  .max\:hover\:bg-grey7:hover {
    --bg-opacity: 1;
    background-color: #dfe2e6;
    background-color: rgba(223, 226, 230, var(--bg-opacity));
  }

  .max\:hover\:bg-grey8:hover {
    --bg-opacity: 1;
    background-color: #f0f2f5;
    background-color: rgba(240, 242, 245, var(--bg-opacity));
  }

  .max\:hover\:bg-grey9:hover {
    --bg-opacity: 1;
    background-color: #f5f7fa;
    background-color: rgba(245, 247, 250, var(--bg-opacity));
  }

  .max\:hover\:bg-white:hover {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .max\:hover\:bg-black:hover {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity));
  }

  .max\:hover\:bg-lightTeal:hover {
    --bg-opacity: 1;
    background-color: #edf8f8;
    background-color: rgba(237, 248, 248, var(--bg-opacity));
  }

  .max\:hover\:bg-teal:hover {
    --bg-opacity: 1;
    background-color: #46b4b4;
    background-color: rgba(70, 180, 180, var(--bg-opacity));
  }

  .max\:hover\:bg-darkTeal:hover {
    --bg-opacity: 1;
    background-color: #46b4b4;
    background-color: rgba(70, 180, 180, var(--bg-opacity));
  }

  .max\:hover\:bg-tealBg:hover {
    --bg-opacity: 1;
    background-color: #eff8f8;
    background-color: rgba(239, 248, 248, var(--bg-opacity));
  }

  .max\:hover\:bg-yellow:hover {
    --bg-opacity: 1;
    background-color: #ff9f40;
    background-color: rgba(255, 159, 64, var(--bg-opacity));
  }

  .max\:hover\:bg-yellowBg:hover {
    --bg-opacity: 1;
    background-color: #fff5eb;
    background-color: rgba(255, 245, 235, var(--bg-opacity));
  }

  .max\:hover\:bg-blue:hover {
    --bg-opacity: 1;
    background-color: #569fe5;
    background-color: rgba(86, 159, 229, var(--bg-opacity));
  }

  .max\:hover\:bg-blueBg:hover {
    --bg-opacity: 1;
    background-color: #ecf6fd;
    background-color: rgba(236, 246, 253, var(--bg-opacity));
  }

  .max\:hover\:bg-red:hover {
    --bg-opacity: 1;
    background-color: #ed6c85;
    background-color: rgba(237, 108, 133, var(--bg-opacity));
  }

  .max\:hover\:bg-redBg:hover {
    --bg-opacity: 1;
    background-color: #fdeff2;
    background-color: rgba(253, 239, 242, var(--bg-opacity));
  }

  .max\:hover\:bg-green:hover {
    --bg-opacity: 1;
    background-color: #4bc0c0;
    background-color: rgba(75, 192, 192, var(--bg-opacity));
  }

  .max\:hover\:bg-error:hover {
    --bg-opacity: 1;
    background-color: #FF0000;
    background-color: rgba(255, 0, 0, var(--bg-opacity));
  }

  .max\:hover\:bg-errorBg:hover {
    --bg-opacity: 1;
    background-color: #ffeded;
    background-color: rgba(255, 237, 237, var(--bg-opacity));
  }

  .max\:hover\:bg-facebook:hover {
    --bg-opacity: 1;
    background-color: #1877f2;
    background-color: rgba(24, 119, 242, var(--bg-opacity));
  }

  .max\:hover\:bg-instagram:hover {
    --bg-opacity: 1;
    background-color: #e73558;
    background-color: rgba(231, 53, 88, var(--bg-opacity));
  }

  .max\:hover\:bg-instagram_basic:hover {
    --bg-opacity: 1;
    background-color: #e73558;
    background-color: rgba(231, 53, 88, var(--bg-opacity));
  }

  .max\:hover\:bg-instagram_graph:hover {
    --bg-opacity: 1;
    background-color: #e73558;
    background-color: rgba(231, 53, 88, var(--bg-opacity));
  }

  .max\:hover\:bg-linkedin:hover {
    --bg-opacity: 1;
    background-color: #1b73b7;
    background-color: rgba(27, 115, 183, var(--bg-opacity));
  }

  .max\:hover\:bg-twitter:hover {
    --bg-opacity: 1;
    background-color: #52a8f0;
    background-color: rgba(82, 168, 240, var(--bg-opacity));
  }

  .max\:hover\:bg-whatsAppGreen:hover {
    --bg-opacity: 1;
    background-color: #25D366;
    background-color: rgba(37, 211, 102, var(--bg-opacity));
  }

  .max\:hover\:bg-socialiePink:hover {
    --bg-opacity: 1;
    background-color: #FC2361;
    background-color: rgba(252, 35, 97, var(--bg-opacity));
  }

  .max\:hover\:bg-socialiePinkHover:hover {
    --bg-opacity: 1;
    background-color: #FF3D71;
    background-color: rgba(255, 61, 113, var(--bg-opacity));
  }

  .max\:hover\:bg-socialieBlue:hover {
    --bg-opacity: 1;
    background-color: #5CC7EA;
    background-color: rgba(92, 199, 234, var(--bg-opacity));
  }

  .max\:hover\:bg-badgeBlue:hover {
    --bg-opacity: 1;
    background-color: #1c90ff;
    background-color: rgba(28, 144, 255, var(--bg-opacity));
  }

  .max\:hover\:bg-success:hover {
    --bg-opacity: 1;
    background-color: #97cc04;
    background-color: rgba(151, 204, 4, var(--bg-opacity));
  }

  .max\:hover\:bg-bodyText:hover {
    --bg-opacity: 1;
    background-color: #5a636e;
    background-color: rgba(90, 99, 110, var(--bg-opacity));
  }

  .max\:hover\:bg-bodyBg:hover {
    --bg-opacity: 1;
    background-color: #f5f7fa;
    background-color: rgba(245, 247, 250, var(--bg-opacity));
  }

  .max\:hover\:bg-border:hover {
    --bg-opacity: 1;
    background-color: #dfe2e6;
    background-color: rgba(223, 226, 230, var(--bg-opacity));
  }

  .max\:hover\:bg-boxShadow:hover {
    background-color: rgba(0, 0, 0, 0.12);
  }

  .max\:hover\:bg-darkBorder:hover {
    --bg-opacity: 1;
    background-color: #c9ced4;
    background-color: rgba(201, 206, 212, var(--bg-opacity));
  }

  .max\:hover\:bg-darkerBorder:hover {
    --bg-opacity: 1;
    background-color: #5a636e;
    background-color: rgba(90, 99, 110, var(--bg-opacity));
  }

  .max\:hover\:bg-darkestGreyBg:hover {
    --bg-opacity: 1;
    background-color: #5a636e;
    background-color: rgba(90, 99, 110, var(--bg-opacity));
  }

  .max\:hover\:bg-darkGreyBg:hover {
    --bg-opacity: 1;
    background-color: #c9ced4;
    background-color: rgba(201, 206, 212, var(--bg-opacity));
  }

  .max\:hover\:bg-darkText:hover {
    --bg-opacity: 1;
    background-color: #101820;
    background-color: rgba(16, 24, 32, var(--bg-opacity));
  }

  .max\:hover\:bg-draftBuilderBg:hover {
    --bg-opacity: 1;
    background-color: #1c252f;
    background-color: rgba(28, 37, 47, var(--bg-opacity));
  }

  .max\:hover\:bg-semiBlack:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }

  .max\:hover\:bg-semiWhite:hover {
    background-color: rgba(255, 255, 255, 0.8);
  }

  .max\:hover\:bg-focus:hover {
    --bg-opacity: 1;
    background-color: #c9ced4;
    background-color: rgba(201, 206, 212, var(--bg-opacity));
  }

  .max\:hover\:bg-grey:hover {
    --bg-opacity: 1;
    background-color: #5a636e;
    background-color: rgba(90, 99, 110, var(--bg-opacity));
  }

  .max\:hover\:bg-greyBg:hover {
    --bg-opacity: 1;
    background-color: #f0f2f5;
    background-color: rgba(240, 242, 245, var(--bg-opacity));
  }

  .max\:hover\:bg-hoverBg:hover {
    background-color: rgba(240,242,245,0.25);
  }

  .max\:hover\:bg-inverseBorder:hover {
    --bg-opacity: 1;
    background-color: #343d46;
    background-color: rgba(52, 61, 70, var(--bg-opacity));
  }

  .max\:hover\:bg-messageBg:hover {
    --bg-opacity: 1;
    background-color: #36a2eb;
    background-color: rgba(54, 162, 235, var(--bg-opacity));
  }

  .max\:hover\:bg-lightBorder:hover {
    --bg-opacity: 1;
    background-color: #f0f2f5;
    background-color: rgba(240, 242, 245, var(--bg-opacity));
  }

  .max\:hover\:bg-lighterText:hover {
    --bg-opacity: 1;
    background-color: #c9ced4;
    background-color: rgba(201, 206, 212, var(--bg-opacity));
  }

  .max\:hover\:bg-lightIcon:hover {
    --bg-opacity: 1;
    background-color: #c9ced4;
    background-color: rgba(201, 206, 212, var(--bg-opacity));
  }

  .max\:hover\:bg-lightText:hover {
    --bg-opacity: 1;
    background-color: #9198a1;
    background-color: rgba(145, 152, 161, var(--bg-opacity));
  }

  .max\:hover\:bg-link:hover {
    --bg-opacity: 1;
    background-color: #569fe5;
    background-color: rgba(86, 159, 229, var(--bg-opacity));
  }

  .max\:hover\:bg-default:hover {
    --bg-opacity: 1;
    background-color: #5a636e;
    background-color: rgba(90, 99, 110, var(--bg-opacity));
  }

  .max\:focus\:bg-transparent:focus {
    background-color: transparent;
  }

  .max\:focus\:bg-grey1:focus {
    --bg-opacity: 1;
    background-color: #101820;
    background-color: rgba(16, 24, 32, var(--bg-opacity));
  }

  .max\:focus\:bg-grey2:focus {
    --bg-opacity: 1;
    background-color: #1c252f;
    background-color: rgba(28, 37, 47, var(--bg-opacity));
  }

  .max\:focus\:bg-grey3:focus {
    --bg-opacity: 1;
    background-color: #343d46;
    background-color: rgba(52, 61, 70, var(--bg-opacity));
  }

  .max\:focus\:bg-grey4:focus {
    --bg-opacity: 1;
    background-color: #5a636e;
    background-color: rgba(90, 99, 110, var(--bg-opacity));
  }

  .max\:focus\:bg-grey5:focus {
    --bg-opacity: 1;
    background-color: #9198a1;
    background-color: rgba(145, 152, 161, var(--bg-opacity));
  }

  .max\:focus\:bg-grey6:focus {
    --bg-opacity: 1;
    background-color: #c9ced4;
    background-color: rgba(201, 206, 212, var(--bg-opacity));
  }

  .max\:focus\:bg-grey7:focus {
    --bg-opacity: 1;
    background-color: #dfe2e6;
    background-color: rgba(223, 226, 230, var(--bg-opacity));
  }

  .max\:focus\:bg-grey8:focus {
    --bg-opacity: 1;
    background-color: #f0f2f5;
    background-color: rgba(240, 242, 245, var(--bg-opacity));
  }

  .max\:focus\:bg-grey9:focus {
    --bg-opacity: 1;
    background-color: #f5f7fa;
    background-color: rgba(245, 247, 250, var(--bg-opacity));
  }

  .max\:focus\:bg-white:focus {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .max\:focus\:bg-black:focus {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity));
  }

  .max\:focus\:bg-lightTeal:focus {
    --bg-opacity: 1;
    background-color: #edf8f8;
    background-color: rgba(237, 248, 248, var(--bg-opacity));
  }

  .max\:focus\:bg-teal:focus {
    --bg-opacity: 1;
    background-color: #46b4b4;
    background-color: rgba(70, 180, 180, var(--bg-opacity));
  }

  .max\:focus\:bg-darkTeal:focus {
    --bg-opacity: 1;
    background-color: #46b4b4;
    background-color: rgba(70, 180, 180, var(--bg-opacity));
  }

  .max\:focus\:bg-tealBg:focus {
    --bg-opacity: 1;
    background-color: #eff8f8;
    background-color: rgba(239, 248, 248, var(--bg-opacity));
  }

  .max\:focus\:bg-yellow:focus {
    --bg-opacity: 1;
    background-color: #ff9f40;
    background-color: rgba(255, 159, 64, var(--bg-opacity));
  }

  .max\:focus\:bg-yellowBg:focus {
    --bg-opacity: 1;
    background-color: #fff5eb;
    background-color: rgba(255, 245, 235, var(--bg-opacity));
  }

  .max\:focus\:bg-blue:focus {
    --bg-opacity: 1;
    background-color: #569fe5;
    background-color: rgba(86, 159, 229, var(--bg-opacity));
  }

  .max\:focus\:bg-blueBg:focus {
    --bg-opacity: 1;
    background-color: #ecf6fd;
    background-color: rgba(236, 246, 253, var(--bg-opacity));
  }

  .max\:focus\:bg-red:focus {
    --bg-opacity: 1;
    background-color: #ed6c85;
    background-color: rgba(237, 108, 133, var(--bg-opacity));
  }

  .max\:focus\:bg-redBg:focus {
    --bg-opacity: 1;
    background-color: #fdeff2;
    background-color: rgba(253, 239, 242, var(--bg-opacity));
  }

  .max\:focus\:bg-green:focus {
    --bg-opacity: 1;
    background-color: #4bc0c0;
    background-color: rgba(75, 192, 192, var(--bg-opacity));
  }

  .max\:focus\:bg-error:focus {
    --bg-opacity: 1;
    background-color: #FF0000;
    background-color: rgba(255, 0, 0, var(--bg-opacity));
  }

  .max\:focus\:bg-errorBg:focus {
    --bg-opacity: 1;
    background-color: #ffeded;
    background-color: rgba(255, 237, 237, var(--bg-opacity));
  }

  .max\:focus\:bg-facebook:focus {
    --bg-opacity: 1;
    background-color: #1877f2;
    background-color: rgba(24, 119, 242, var(--bg-opacity));
  }

  .max\:focus\:bg-instagram:focus {
    --bg-opacity: 1;
    background-color: #e73558;
    background-color: rgba(231, 53, 88, var(--bg-opacity));
  }

  .max\:focus\:bg-instagram_basic:focus {
    --bg-opacity: 1;
    background-color: #e73558;
    background-color: rgba(231, 53, 88, var(--bg-opacity));
  }

  .max\:focus\:bg-instagram_graph:focus {
    --bg-opacity: 1;
    background-color: #e73558;
    background-color: rgba(231, 53, 88, var(--bg-opacity));
  }

  .max\:focus\:bg-linkedin:focus {
    --bg-opacity: 1;
    background-color: #1b73b7;
    background-color: rgba(27, 115, 183, var(--bg-opacity));
  }

  .max\:focus\:bg-twitter:focus {
    --bg-opacity: 1;
    background-color: #52a8f0;
    background-color: rgba(82, 168, 240, var(--bg-opacity));
  }

  .max\:focus\:bg-whatsAppGreen:focus {
    --bg-opacity: 1;
    background-color: #25D366;
    background-color: rgba(37, 211, 102, var(--bg-opacity));
  }

  .max\:focus\:bg-socialiePink:focus {
    --bg-opacity: 1;
    background-color: #FC2361;
    background-color: rgba(252, 35, 97, var(--bg-opacity));
  }

  .max\:focus\:bg-socialiePinkHover:focus {
    --bg-opacity: 1;
    background-color: #FF3D71;
    background-color: rgba(255, 61, 113, var(--bg-opacity));
  }

  .max\:focus\:bg-socialieBlue:focus {
    --bg-opacity: 1;
    background-color: #5CC7EA;
    background-color: rgba(92, 199, 234, var(--bg-opacity));
  }

  .max\:focus\:bg-badgeBlue:focus {
    --bg-opacity: 1;
    background-color: #1c90ff;
    background-color: rgba(28, 144, 255, var(--bg-opacity));
  }

  .max\:focus\:bg-success:focus {
    --bg-opacity: 1;
    background-color: #97cc04;
    background-color: rgba(151, 204, 4, var(--bg-opacity));
  }

  .max\:focus\:bg-bodyText:focus {
    --bg-opacity: 1;
    background-color: #5a636e;
    background-color: rgba(90, 99, 110, var(--bg-opacity));
  }

  .max\:focus\:bg-bodyBg:focus {
    --bg-opacity: 1;
    background-color: #f5f7fa;
    background-color: rgba(245, 247, 250, var(--bg-opacity));
  }

  .max\:focus\:bg-border:focus {
    --bg-opacity: 1;
    background-color: #dfe2e6;
    background-color: rgba(223, 226, 230, var(--bg-opacity));
  }

  .max\:focus\:bg-boxShadow:focus {
    background-color: rgba(0, 0, 0, 0.12);
  }

  .max\:focus\:bg-darkBorder:focus {
    --bg-opacity: 1;
    background-color: #c9ced4;
    background-color: rgba(201, 206, 212, var(--bg-opacity));
  }

  .max\:focus\:bg-darkerBorder:focus {
    --bg-opacity: 1;
    background-color: #5a636e;
    background-color: rgba(90, 99, 110, var(--bg-opacity));
  }

  .max\:focus\:bg-darkestGreyBg:focus {
    --bg-opacity: 1;
    background-color: #5a636e;
    background-color: rgba(90, 99, 110, var(--bg-opacity));
  }

  .max\:focus\:bg-darkGreyBg:focus {
    --bg-opacity: 1;
    background-color: #c9ced4;
    background-color: rgba(201, 206, 212, var(--bg-opacity));
  }

  .max\:focus\:bg-darkText:focus {
    --bg-opacity: 1;
    background-color: #101820;
    background-color: rgba(16, 24, 32, var(--bg-opacity));
  }

  .max\:focus\:bg-draftBuilderBg:focus {
    --bg-opacity: 1;
    background-color: #1c252f;
    background-color: rgba(28, 37, 47, var(--bg-opacity));
  }

  .max\:focus\:bg-semiBlack:focus {
    background-color: rgba(0, 0, 0, 0.8);
  }

  .max\:focus\:bg-semiWhite:focus {
    background-color: rgba(255, 255, 255, 0.8);
  }

  .max\:focus\:bg-focus:focus {
    --bg-opacity: 1;
    background-color: #c9ced4;
    background-color: rgba(201, 206, 212, var(--bg-opacity));
  }

  .max\:focus\:bg-grey:focus {
    --bg-opacity: 1;
    background-color: #5a636e;
    background-color: rgba(90, 99, 110, var(--bg-opacity));
  }

  .max\:focus\:bg-greyBg:focus {
    --bg-opacity: 1;
    background-color: #f0f2f5;
    background-color: rgba(240, 242, 245, var(--bg-opacity));
  }

  .max\:focus\:bg-hoverBg:focus {
    background-color: rgba(240,242,245,0.25);
  }

  .max\:focus\:bg-inverseBorder:focus {
    --bg-opacity: 1;
    background-color: #343d46;
    background-color: rgba(52, 61, 70, var(--bg-opacity));
  }

  .max\:focus\:bg-messageBg:focus {
    --bg-opacity: 1;
    background-color: #36a2eb;
    background-color: rgba(54, 162, 235, var(--bg-opacity));
  }

  .max\:focus\:bg-lightBorder:focus {
    --bg-opacity: 1;
    background-color: #f0f2f5;
    background-color: rgba(240, 242, 245, var(--bg-opacity));
  }

  .max\:focus\:bg-lighterText:focus {
    --bg-opacity: 1;
    background-color: #c9ced4;
    background-color: rgba(201, 206, 212, var(--bg-opacity));
  }

  .max\:focus\:bg-lightIcon:focus {
    --bg-opacity: 1;
    background-color: #c9ced4;
    background-color: rgba(201, 206, 212, var(--bg-opacity));
  }

  .max\:focus\:bg-lightText:focus {
    --bg-opacity: 1;
    background-color: #9198a1;
    background-color: rgba(145, 152, 161, var(--bg-opacity));
  }

  .max\:focus\:bg-link:focus {
    --bg-opacity: 1;
    background-color: #569fe5;
    background-color: rgba(86, 159, 229, var(--bg-opacity));
  }

  .max\:focus\:bg-default:focus {
    --bg-opacity: 1;
    background-color: #5a636e;
    background-color: rgba(90, 99, 110, var(--bg-opacity));
  }

  .max\:bg-opacity-0 {
    --bg-opacity: 0;
  }

  .max\:bg-opacity-10 {
    --bg-opacity: 0.1;
  }

  .max\:bg-opacity-25 {
    --bg-opacity: 0.25;
  }

  .max\:bg-opacity-50 {
    --bg-opacity: 0.5;
  }

  .max\:bg-opacity-75 {
    --bg-opacity: 0.75;
  }

  .max\:bg-opacity-92 {
    --bg-opacity: 0.92px;
  }

  .max\:bg-opacity-100 {
    --bg-opacity: 1;
  }

  .max\:hover\:bg-opacity-0:hover {
    --bg-opacity: 0;
  }

  .max\:hover\:bg-opacity-10:hover {
    --bg-opacity: 0.1;
  }

  .max\:hover\:bg-opacity-25:hover {
    --bg-opacity: 0.25;
  }

  .max\:hover\:bg-opacity-50:hover {
    --bg-opacity: 0.5;
  }

  .max\:hover\:bg-opacity-75:hover {
    --bg-opacity: 0.75;
  }

  .max\:hover\:bg-opacity-92:hover {
    --bg-opacity: 0.92px;
  }

  .max\:hover\:bg-opacity-100:hover {
    --bg-opacity: 1;
  }

  .max\:focus\:bg-opacity-0:focus {
    --bg-opacity: 0;
  }

  .max\:focus\:bg-opacity-10:focus {
    --bg-opacity: 0.1;
  }

  .max\:focus\:bg-opacity-25:focus {
    --bg-opacity: 0.25;
  }

  .max\:focus\:bg-opacity-50:focus {
    --bg-opacity: 0.5;
  }

  .max\:focus\:bg-opacity-75:focus {
    --bg-opacity: 0.75;
  }

  .max\:focus\:bg-opacity-92:focus {
    --bg-opacity: 0.92px;
  }

  .max\:focus\:bg-opacity-100:focus {
    --bg-opacity: 1;
  }

  .max\:bg-bottom {
    background-position: bottom;
  }

  .max\:bg-center {
    background-position: center;
  }

  .max\:bg-left {
    background-position: left;
  }

  .max\:bg-left-bottom {
    background-position: left bottom;
  }

  .max\:bg-left-top {
    background-position: left top;
  }

  .max\:bg-right {
    background-position: right;
  }

  .max\:bg-right-bottom {
    background-position: right bottom;
  }

  .max\:bg-right-top {
    background-position: right top;
  }

  .max\:bg-top {
    background-position: top;
  }

  .max\:bg-repeat {
    background-repeat: repeat;
  }

  .max\:bg-no-repeat {
    background-repeat: no-repeat;
  }

  .max\:bg-repeat-x {
    background-repeat: repeat-x;
  }

  .max\:bg-repeat-y {
    background-repeat: repeat-y;
  }

  .max\:bg-repeat-round {
    background-repeat: round;
  }

  .max\:bg-repeat-space {
    background-repeat: space;
  }

  .max\:bg-auto {
    background-size: auto;
  }

  .max\:bg-cover {
    background-size: cover;
  }

  .max\:bg-contain {
    background-size: contain;
  }
}

