*,
:after,
:before {
  box-sizing: border-box;
  margin: 0;
  @apply border-border;
  border-top-style: solid;
  border-top-width: 0px;
  border-right-style: solid;
  border-right-width: 0px;
  border-bottom-style: solid;
  border-bottom-width: 0px;
  border-left-style: solid;
  border-left-width: 0px;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
}

html {
  height: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  -webkit-font-smoothing: antialiased;
  font-family: sofia-pro, sans-serif;
}

a {
  @apply no-underline;
}

button {
  outline: none;
  border: 0;
  background: none;
  cursor: pointer;
  padding: 0;
  text-align: inherit;
  text-transform: inherit;
  letter-spacing: inherit;
  color: inherit;
  font: inherit;
}

button:focus {
  outline: none;
}

@import './utilities/auto-size.css';

@import './utilities/border.css';

@import './utilities/btn.css';

@import './utilities/bump.css';

@import './utilities/card.css';

@import './utilities/flyout-menu.css';

@import './utilities/input.css';

@import './utilities/tippy.css';

@tailwind components;
@tailwind utilities;
