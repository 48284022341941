.tippy-popper {
  max-width: none;
}

.tippy-tooltip.socialie-theme {
  @apply rounded-sm shadow-md bg-white py-0.5 px-1 text-bodyText;
}

.tippy-tooltip.FlyoutMenu-theme {
  @apply rounded-sm shadow-md bg-white py-0.5 px-1 text-bodyText overflow-y-auto
  text-left;
  min-width: 12rem;
  max-height: 16rem;
}
