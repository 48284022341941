.bump-down-0\\.5 {
  position: relative;
  top: 0.5px;
}

.bump-down-1 {
  position: relative;
  top: 1px;
}

.bump-down-1\\.5 {
  position: relative;
  top: 1.5px;
}

.bump-down-2 {
  position: relative;
  top: 2px;
}

.bump-up-0\\.5 {
  position: relative;
  top: -0.5px;
}

.bump-up-1 {
  position: relative;
  top: -1px;
}

.bump-up-1\.5 {
  position: relative;
  top: -1.5px;
}

.bump-up-2 {
  position: relative;
  top: -2px;
}
