.input {
  @apply w-full h-4.5 p-1 block bg-white border border-solid border-grey7 rounded font-sans;
}

.input::placeholder {
  @apply text-light;
}

input:focus {
  @apply outline-none shadow-outline;
}
