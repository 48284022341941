.btn {
  @apply inline-block rounded text-center p-2 py-1.5 leading-20 font-semibold;
  padding-top: 11px;
  padding-bottom: 13px;
}

.btn-sm {
  @apply p-1.25 text-14 leading-18;
  padding-top: 7px;
  padding-bottom: 9px;
}

.btn:disabled {
  opacity: 0.75;
  cursor: not-allowed;
}

.btn:hover {
  text-decoration: none;
}

.btn-primary {
  @apply bg-socialiePink text-white font-semibold;
}

.btn-primary:hover {
  @apply bg-socialiePinkHover;
}

.btn-secondary {
  @apply bg-white text-socialiePink border;
  border-color: currentColor;
}

.btn-secondary:hover {
  @apply text-socialiePinkHover;
}

.btn-error {
  @apply bg-errorBg text-error;
}

.btn-default {
  @apply bg-grey7 text-dark;
}

.btn-default:hover {
  @apply bg-grey6;
}
